import React from 'react';
import { useAutocomplete,AutocompleteGetTagProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from "uuid";


const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
`,
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid gray;
  background-color: #0f1535;
  border-radius: 13px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    background-color: #0f1535;
    color: #fff;
    font-size: medium;
    font-weight: bold;
    height: 40px;
    border-radius: 13px;
    box-sizing: border-box;
    padding: 6px 10px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  color: whitesmoke;
  font-size: medium;
  height: 24px;
  line-height: 22px;
  width: max-content;
  background-color: transparent;
  border: 1.5px solid gray;
  border-radius: 20px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  margin: 4px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

export default function CustomizedHook({keyword, setKeyword, formState, setFormState}) {

  const {
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [],
    multiple: true,
    options: [],
    getOptionLabel: (option) => option.title,
  });

  const handleOnChanged = (e) => {
    setKeyword( e.target.value );
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && event.currentTarget.value.trim() !== '') {
      let keywords = formState?.keyword;
      keywords.push({id : uuidv4(), title: event.currentTarget.value})
      setKeyword( '' );
    }
  };
  

  return (
    <Root>
      <div>
        <InputWrapper>
          <input
            value={keyword}
            onChange={handleOnChanged}
            onKeyDown={handleInputKeyDown}
          />
        </InputWrapper>
      </div>
    </Root>
  );
}


