import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import "flag-icon-css/css/flag-icons.min.css"
import './style/style.css';
import Divider from "@mui/material/Divider";
import i18next from 'i18next';
import cookies from 'js-cookie';
import { setDirection, useContextUIController } from "context";

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: "gb",
        dir: "ltr"
    },
    {
        code: 'fr',
        name: 'Francies',
        country_code: "fr",
        dir: "ltr"
    },
    {
        code: 'ir',
        name: 'فارسی',
        country_code: "ir",
        dir: "rtl"
    },
];

function LanguageUI(props) {

    const { onClose, open } = props;
    
    const currentLanguageCode = cookies.get('i18next') || 'ir' ;
    const currentLanguage = languages.find( l => l.code === currentLanguageCode ); 
    const [, dispatch] = useContextUIController();

    const changeLanguage = (code) => {

        if(code == "ir"){
            setDirection(dispatch, "rtl");
        }else{
            setDirection(dispatch, "ltr");
        }
    }

    // React.useEffect( () => {
    //     changeLanguage()
    // },[])


    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (code) => {
        i18next.changeLanguage(code);
        changeLanguage(code)
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle alignItems="center" display="flex" justifyContent="center">Set Your Language</DialogTitle>
            <Divider light />
            <List>

                {languages.map(({ code, name, country_code }) => (
                    <ListItem key={country_code} disableGutters sx={{ alignItems: "center !important", display: "flex !important", justifyContent: "center !important" }}>
                        <ListItemButton onClick={() => handleListItemClick(code) }>
                        <ListItemAvatar>
                            <Avatar sx={{ fontSize: "210px" }}>
                            <span className={`flag-icon flag-icon-${country_code}`}></span>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={name} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>
        </Dialog>
    );
}

LanguageUI.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function LanguageUIDemo() {
  const [open, setOpen] = React.useState(false);
  const currentLanguageCode = cookies.get('i18next') || 'ir' ;
  const currentLanguage = languages.find( l => l.code === currentLanguageCode ); 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
        <Avatar onClick={handleClickOpen} sx={{ width: "40px", border: "2px solid #0075ff", height: "40px" ,fontSize: "180px !important", cursor: "pointer" }}>
            <span className={`flag-icon flag-icon-${currentLanguage?.country_code}`}></span>
        </Avatar>
        <LanguageUI
            open={open}
            onClose={handleClose}
        />
    </div>
  );
}