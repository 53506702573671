import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AlertTitle from '@mui/material/AlertTitle';

const SuccessAlarm = ({openAlarams, alarmProps}) => {

    const [open, setOpen] = React.useState(true);

    React.useEffect( () => {
        setOpen(openAlarams)
    },[openAlarams])

    return(
        <Box sx={{ 
            width: '80%', 
            // position: "fixed", 
            // top:"100px", 
            zIndex: 100, 
            // left: 0,
            // right: 0, 
            marginLeft: "auto",
            marginRight: "auto" 
        }}>
            <Collapse in={open}>
                <Alert
                    severity='success'
                    icon={<CheckCircleOutlineIcon sx={{color: "#2C3639 !important",fontSize:"larger !important"}} />} 
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" sx={{color:'#181D31'}} />
                        </IconButton>
                    }
                    sx={{ mb: 2 , background: "#647E68",color: "#181D31"}}
                >

                    { alarmProps?.title && <AlertTitle> { alarmProps?.title } </AlertTitle> }
                    { alarmProps?.msg }
                </Alert>
            </Collapse>
        </Box>
    )
}

const ErrorAlarm = ({openAlarams, alarmProps}) => {

    const [open, setOpen] = React.useState(true);

    React.useEffect( () => {
        setOpen(openAlarams)
    },[openAlarams])

    return(
        <Box sx={{ 
            width: '80%', 
            zIndex: 100,
            marginLeft: "auto",
            marginRight: "auto" 
        }}>
            <Collapse in={open}>
                <Alert
                    severity='error'
                    icon={<ErrorOutlineIcon sx={{color: "#44000D !important",fontSize:"larger !important"}} />} 
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" sx={{color:'#181D31'}} />
                        </IconButton>
                    }
                    sx={{ mb: 2 , background: "#A13333",color: "#181D31"}}
                >

                    { alarmProps?.title && <AlertTitle> { alarmProps?.title } </AlertTitle> }
                    { alarmProps?.msg }
                </Alert>
            </Collapse>
        </Box>
    )
}

export  { SuccessAlarm, ErrorAlarm };