import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from 'react-i18next';
import CustomizedHook from "./AutoComplete";
import { Divider } from "@mui/material";
import Toolbar from "examples/Toolbar";
import { Icon } from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDrag, useDrop } from "react-dnd";
import { gql, useMutation, useQuery } from "@apollo/client";
import VuiButton from "components/VuiButton";


const QUERY_WEBSITES_RANKING = gql`
query ($user_id: uuid!, $status: String!) {
    websites_ranking(where: {_and: [{user_id: {_eq: $user_id}}, {status: {_eq: $status}} ]}) {
        id
        meta
        ownership
        status
        user_id
        website_name
        websites_of_ranking_rel (where: {status: {_eq: "visible"}}) {
            keyword
        }
    }
}
`;

const INSERT_KEYWORDS_WEBSITES_RANKING = gql`
mutation ($webites_ranking_id: uuid!, $keyword: jsonb!, $status: String!, $user_id: uuid!, $meta: jsonb!) {
    insert_keyword_of_websites(objects: {
        webites_ranking_id: $webites_ranking_id, 
        user_id: $user_id, 
        status: $status, 
        keyword: $keyword, 
        meta: $meta
    }) {
        returning {
          id
        }
    }
}
`;

const UPDATE_KEYWORDS_OF_WEBSITES_RANKING = gql`
mutation ($status: String!, $user_id: uuid!) {
    update_keyword_of_websites(where: {user_id: {_eq: $user_id}}, _set: {status: $status}) {
        affected_rows
    }
}
`;

function KeywordManagement({loggedInUser}) {

	const { t: trans, i18n } = useTranslation();
    const { data: websites_ranking_database } = useQuery(QUERY_WEBSITES_RANKING, {variables: {user_id: loggedInUser?.id, status: 'visible'}, notifyOnNetworkStatusChange: true});
    const [insertKeywordsWebsitesRanking,] = useMutation(INSERT_KEYWORDS_WEBSITES_RANKING);
    const [updatedKeywordsOfWebsitesRanking,] = useMutation(UPDATE_KEYWORDS_OF_WEBSITES_RANKING);
    const [ keyword, setKeyword ] = useState([]);
    const [ websites, setWebsites ] = useState([]);
    const [ formState, setFormState ] = useState({
        keyword: [],
    });

    useEffect( () => {

        websites_ranking_database?.websites_ranking.forEach(w => {

            if(w?.websites_of_ranking_rel?.length < 1){

                setWebsites(prev => [...prev, {name: w?.website_name, keywords: [], id: w?.id}]);
            }else{

                let keywords = [];

                w?.websites_of_ranking_rel?.forEach( k => { 
                    keywords?.push({id: k?.keyword?.id, title: k?.keyword?.title});
                });

                setWebsites(prev => [...prev, {name: w?.website_name, keywords, id: w?.id}]);

                setFormState(prevState => {

                    const currentTitles = new Set(prevState.keyword.map(k => k.title));
                    const uniqueNewKeywords = keywords.filter(k => !currentTitles.has(k.title));
                    return {
                        ...prevState,
                        keyword: [...prevState.keyword, ...uniqueNewKeywords]
                    };
                });
                
            }
        });

    }, [websites_ranking_database]);

    const handleRemoveItem = (itemId) => {
        const updatedKeyword = formState.keyword.filter((item) => item.id !== itemId);
        setFormState({
            ...formState,
            keyword: updatedKeyword,
        });

        const updatedWebsites = websites.map((website) => ({
            ...website,
            keywords: website.keywords.filter((keyword) => keyword.id !== itemId),
        }));

        setWebsites(updatedWebsites);
    };

    function DraggableKeyword({ keyword, handleRemoveItem }) {

        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'keyword',
            item: { id: keyword.id, title: keyword.title },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));
      
        return (
            <VuiBox
                ref={drag}
                key={keyword.id}
                p={5}
                sx={{
                    cursor: 'pointer',
                    display: "flex",
                    justifyContent: "space-between",
                    margin: 2,
                    padding: 2,
                    borderRadius: "10px",
                    boxShadow: isDragging
                        ? "0px 0px 15px 5px rgba(0, 0, 255, 0.5)"
                        : "5px 6px 16px 1px rgba(0,0,0,0.6) !important",
                    opacity: isDragging ? 0.5 : 1,
                }}
            >
                <VuiTypography color="white" sx={{ fontSize: 15 }}>
                    {keyword?.title}
                </VuiTypography>
                <RemoveCircleOutlineIcon
                    onClick={() => handleRemoveItem(keyword.id)} 
                    sx={{
                        fontWeight: "bold",
                        fontSize: "25px !important",
                        color: "white !important",
                        cursor: "pointer"
                    }}
                />
            </VuiBox>
        );
    }

    function DropableKeyword({ website, onItemDropped, onKeywordRemove}) {

        const [removingKeywordId, setRemovingKeywordId] = useState(null);

        const removeKeywordWithAnimation = (websiteName, keywordId) => {
            setRemovingKeywordId(keywordId);

            setTimeout(() => {
                onKeywordRemove(websiteName, keywordId);
                setRemovingKeywordId(null);
            }, 1500);
        };


        const [{ isOver }, drop] = useDrop(() => ({
            accept: 'keyword',
            drop: (item, monitor) => {
                onItemDropped({item, websiteName: website?.name});
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }), [website?.name] );

        return (
            <Grid ref={drop} key={website?.name} item xs={12} md={6} px={1}>
                <VuiBox p={5}
                    sx={{ 
                        // display: "flex", 
                        width: "100% !important",
                        padding: 2,
                        borderRadius: "10px",
                        boxShadow: "5px 6px 16px 1px rgba(0,0,0,0.6) !important"
                    }}
                >
                    <VuiTypography 
                        color="white" 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center"
                        sx={{
                            fontSize: 15, 
                        }}>
                        {website?.name}
                    </VuiTypography>

                    <Divider variant="middle" />

                    {
                        website?.keywords?.map( (k) => (
                            
                            <VuiBox p={5} 
                                key={k?.id}
                                
                                sx={{ 
                                    display: "flex", 
                                    width: "100% !important",
                                    justifyContent: "space-between",  // Add this line
                                    padding: 2,
                                    margin: "15px 0px",
                                    borderRadius: "10px",
                                    boxShadow: "5px 6px 16px 1px rgba(0,0,0,0.6) !important",
                                    transition: 'opacity 1.5s ease',
                                    opacity: removingKeywordId === k.id ? 0 : 1,
                                }}
                            >
                                <VuiTypography color="white" sx={{fontSize: 15,}}>
                                    {k?.title}
                                </VuiTypography>

                                <RemoveCircleOutlineIcon    
                                    onClick={() => removeKeywordWithAnimation(website.name, k.id)}
                                    sx={{ 
                                        fontWeight: "bold", 
                                        fontSize: "25px !important", 
                                        color: "white !important",
                                        cursor: "pointer"
                                    }}
                                />
                            </VuiBox>
                        ))
                    }
                </VuiBox>
            </Grid>
        );
    }

    function handleDropped ({item, websiteName}) {

        const websiteIndex = websites.findIndex((website) => website.name === websiteName);

        if (websiteIndex === -1) {
            console.error('Website not found');
            return; 
        }

        const keywordExists = websites[websiteIndex].keywords.some((k) => k.id === item.id);

        if (keywordExists) {
            console.error('Keyword already exists in the selected website');
            return; 
        }

        const newKeyword = { id: item.id, title: item.title };
        const newWebsites = [...websites];

        newWebsites[websiteIndex] = {
            ...newWebsites[websiteIndex],
            keywords: [...newWebsites[websiteIndex].keywords, newKeyword],
        };

        setWebsites(newWebsites);
    }

    const handleRemoveKeywordFromWebsite = (websiteName, keywordId) => {

        const updatedWebsites = websites.map((website) => {
          if (website.name === websiteName) {
            return {
              ...website,
              keywords: website.keywords.filter((keyword) => keyword.id !== keywordId),
            };
          }
          return website;
        });
    
        setWebsites(updatedWebsites);
    };

    const handleKeywordsWebsitesRanking = async () => {

        let  mutationResult = await updatedKeywordsOfWebsitesRanking({
            variables: {
                user_id: loggedInUser?.id,
                status: 'invisible',
            }
        });

        websites?.forEach( async w => {

            if( w.keywords && w.keywords?.length > 0 ){

                w?.keywords.forEach( async k => {

                    let mutationResult = await insertKeywordsWebsitesRanking({
                        variables: {
                            meta: {},
                            keyword: {title: k?.title, id: k?.id},
                            status: 'visible',
                            user_id: loggedInUser?.id,
                            webites_ranking_id: w?.id
                        }
                    });
                });
            }
        });
    }

    return (
        <DashboardLayout>
                        
            <DashboardNavbar />
            
            <Grid minHeight={'100vh'} container py="50px" px="15px" display="flex" justifyContent="center" alignItems="center" >
            
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card sx={{padding: "80px 0px"}}>
                        
                        <Toolbar backlinkUrl={'/rank'} />

                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">
                            
                                <Grid item xs={12} md={8} px={5}>
                                    <VuiBox p={5} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white"
                                                sx={{ fontSize: 15 }}
                                            >
                                                {trans("rank_create_new_keyword")} 
                                            </VuiTypography>
                                        </VuiBox>
                                        <CustomizedHook 
                                            keyword={keyword} 
                                            setKeyword={setKeyword} 
                                            formState={formState}
                                            setFormState={setFormState} 
                                        />
                                    </VuiBox>
                                </Grid>
                                
                                <Divider variant="middle" />

                            </Grid>
                        </VuiBox>

                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <Grid container justifyContent="center" spacing={3} pt={3}>
                            
                                <Grid item xs={12} md={5} px={5}>
                                    
                                    <Grid item xs={12} pt={3} px={1} className="centerCloumn">
                                        <VuiTypography component="label" variant="button" color="white" fontWeight="medium" sx={{ fontSize:15}} >
                                            {trans("rank_keywords")}
                                        </VuiTypography>
                                        <Divider variant="middle" />
                                    </Grid>
                                    {formState?.keyword &&
                                        formState?.keyword.map((k) => (
                                            <DraggableKeyword key={k.id} keyword={k} handleRemoveItem={handleRemoveItem} />
                                        ))
                                    }
                                </Grid>

                                <Grid className="center" item xs={12} md={7} px={5}>
                                    <Grid className="center" container spacing={3} pt={3} >
                                        <Grid item xs={12} px={1} className="centerCloumn">
                                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium" sx={{ fontSize:15}} >
                                                {trans("rank_websites")}
                                            </VuiTypography>
                                            <Divider variant="middle" />
                                        </Grid>
                                        {
                                            websites?.map( (w) => (
                                                <DropableKeyword website={w} onItemDropped={handleDropped} onKeywordRemove={handleRemoveKeywordFromWebsite} />
                                            ))
                                        }
                                    </Grid>
                                </Grid>

                                <Divider variant="middle" />

                                <Grid item xs={8}>
                                    <VuiBox mb={1}>
                                        <VuiButton onClick={handleKeywordsWebsitesRanking} color="info" fullWidth size='medium'>
                                            {trans("publicMassage_submit")}
                                        </VuiButton>
                                    </VuiBox>
                                </Grid>
                            </Grid>
                        </VuiBox>

                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    );
}

export default KeywordManagement;
