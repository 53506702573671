import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import SubscriptionCards from "./components/Subscription";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/subscriptions/components/Header";
import { useContextUIController } from "context";
import BuyOneSubscription from "./components/Modal";
import {gql, useMutation, useQuery} from '@apollo/client';
import Loader from "examples/loader";
import * as React from 'react';
import SubscriptionColoumns from "./components/Skeleton/SubscriptionColoumns";

const QUERY_SUBSCRIPTION = gql`
query {
  subscription_models {
    model_name
    subscription_types {
      subscription_name
      subscription_model_id
      time_to_expire
      status
      prices
      meta
      id
      facilities
      created_at
      languages
    }
    languages
  }
}

`;

function Overview() {

  const [, dispatch] = useContextUIController();
  const [tabValue, setTabValue] = useState(0);
  const [cardsModel, setCardsModel ] = useState([]);
  const {loading, error, data, refetch} = useQuery(QUERY_SUBSCRIPTION, {variables: {}, notifyOnNetworkStatusChange: true});
  const [loader, setLoader] = useState(false);
  
  const handleSetTabValue = (event, newValue) => { 
    if(newValue !== tabValue) {
      setTabValue(newValue);
    }
  };
  useEffect( () => {
    let arr = [];
    if(data){
      setLoader(false);

      data?.subscription_models?.forEach(m => {
          m?.subscription_types?.forEach(s => {
            if(s?.subscription_name == "1month" && tabValue == 0){
              s["subscription_model"] = m?.languages;
              arr?.push(s);
            }
          });
      });
    }
    setCardsModel(arr)
  },[data])

  useEffect( () => {
    let arr = [];

    if(data){
      
      data?.subscription_models?.forEach(m => {
        m?.subscription_types?.forEach(s => {
          if(s?.subscription_name == "1month" && tabValue == 0){
            s["subscription_model"] = m?.languages;
            arr?.push(s);
          }else if(s?.subscription_name == "6month" && tabValue == 1) {
            s["subscription_model"] = m?.languages;
            arr?.push(s);
          }else if(s?.subscription_name == "1year" && tabValue == 2) {
            s["subscription_model"] = m?.languages;
            arr?.push(s);
          }
        });
      });
    }

    setCardsModel(arr)
  },[tabValue])
  

  return (
    <DashboardLayout>
      {/* { loader && <Loader setLoader={loader} /> } */}
      <BuyOneSubscription  />
      <Header handleSetTabValue={handleSetTabValue} tabValue={tabValue} setTabValue={setTabValue} />
      <Grid container px="15px" my="30px" alignItems="center" display="flex" justifyContent="center">
        <Grid item xs={12} xl={11} lg={11}>
          <Card>
            <VuiBox display="flex" flexDirection="column" height="100%">
              <Grid container spacing={3}>
                { cardsModel.length > 0 && < SubscriptionCards subscriptions={cardsModel} /> }
                { cardsModel.length < 1 && < SubscriptionColoumns />  }
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Overview;
