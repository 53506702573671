import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Suggestion from "layouts/suggestions";
import Overview from "layouts/profile";
import Subscriptions from "layouts/subscriptions";
import { BsFillPersonFill } from "react-icons/bs";
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import Category from "layouts/suggestions/components/category/index";

const routes = [
  {
    type: "collapse",
    name: "sidenav_route__dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "sidenav_route__seo",
    key: "suggestion",
    route: "/suggestion",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Suggestion,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Rank",
    key: "rank",
    route: "/rank",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Category,
    noCollapse: true,
  },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "sidenav_route__subscriptions",
    key: "subscriptions",
    route: "/subscriptions",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: Subscriptions,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "sidenav_route__profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Overview,
    noCollapse: true,
  },
  
];



export default routes;
