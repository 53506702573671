import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import EventNoteIcon from '@mui/icons-material/EventNote';
import VuiBox from "components/VuiBox";
import gif from "assets/images/header-background.png";
import VuiButton from "components/VuiButton";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Divider from "@mui/material/Divider";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import '../../styles/index.css';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import Loader from 'examples/loader';
import { useTranslation } from 'react-i18next';
import PaymentGateway from './PaymentGateway';

const ACTION_PAYMENT_GATEWAY = gql`
    mutation ($subscription_id: uuid!, $payment_method: String!) {
        action_payment_gateway(subscription_id: $subscription_id, payment_method: $payment_method) {
            success
            alert
            data
        }
    }  
`;

export default function SubscriptionModal({
    open, 
    handleClose,
    formState
}) {

    const [loader, setLoader] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState("zarinpal");
    const [goPaymentGateway,] = useMutation(ACTION_PAYMENT_GATEWAY);
	const { t: trans, i18n } = useTranslation();

    const handlePaymentGateway = async () => {
        setLoader(true);
        handleClose(true);
        const result = await goPaymentGateway({variables: {subscription_id: formState?.id, payment_method: paymentMethod} });
        setLoader(false)
        window.open(result?.data?.action_payment_gateway?.data?.url , '_blank');
    }

    return (
        <>
            {
                loader && <Loader setLoader={loader} />
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth={true}
                sx={{
                    borderRadius: '20px !important'
                }}
            >
                <VuiBox                
                    sx={{
                        padding: "10px",
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)),url(${gif})`,
                        backgroundSize: "cover",
                    }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <VuiTypography 
                                variant="h5" 
                                color="white" 
                                fontWeight="bold" 
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <LocalMallIcon sx={{color: '#0075ff', mr:'3px', fontSize:'25px !important' }} />
                                {trans("subscription_modal_subscriptionPackage")} {formState?.subscription_name}
                            </VuiTypography>
                        </DialogContentText>
                    </DialogContent>

                    <Divider variant="middle" />

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container alignItems="center">
                                <Grid item sm={6} xs={12}>
                                    <VuiTypography 
                                        variant="h6" 
                                        color="white" 
                                        fontWeight="bold" 
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <EventNoteIcon sx={{color: '#0075ff', mr:'3px', fontSize:'25px !important' }} />
                                        {trans("publicMassage_duration")} {formState?.time_to_expire} {trans("publicMassage_day")}
                                    </VuiTypography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <VuiTypography 
                                        variant="h6" 
                                        color="white" 
                                        fontWeight="bold" 
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CreditCardIcon sx={{color: '#0075ff', mr:'3px', fontSize:'25px !important' }} />
                                        {formState?.price?.languages?.[i18n?.language]?.price_after_discount} {formState?.price?.languages?.[i18n?.language]?.["price-unit"]}
                                    </VuiTypography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    
                    <Divider variant="middle" />

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <VuiTypography 
                                variant="h6" 
                                color="white" 
                                fontWeight="bold" 
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <StarPurple500Icon sx={{color: 'red', mr:'3px', fontSize:'20px !important' }} />
                                {trans("subscription_modal_expireTheOtherInvoice")}
                            </VuiTypography>
                        </DialogContentText>
                    </DialogContent>
                    
                    <Divider variant="middle" />
                        <PaymentGateway setPaymentMethod={setPaymentMethod} />
                    <Divider variant="middle" />

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <VuiTypography 
                                variant="h6" 
                                color="white" 
                                fontWeight="bold" 
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {trans("subscription_modal_buyNewPackage")}
                            </VuiTypography>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <VuiButton onClick={handlePaymentGateway} color="success" sx={{width: '50%'}} fontWeight="medium">
                            {trans("publicMassage_submit")}
                        </VuiButton>
                        <VuiButton onClick={handleClose} color="error" sx={{width: '50%'}} fontWeight="medium">
                            {trans("publicMassage_cancel")}
                        </VuiButton>
                    </DialogActions>
                </VuiBox>
                
            </Dialog>
        </>
    );
}