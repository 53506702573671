import VuiBox from "components/VuiBox";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import VuiTypography from "components/VuiTypography";
import CreditBalance from "layouts/subscriptions/components/PriceStyledCard";
import { changeTimeZone } from "utilities/Utils";
import VuiButton from "components/VuiButton";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";

function SubscriptionRow({invoicesData}) {

	const { t: trans, i18n } = useTranslation();
    let invoice_subscription = invoicesData?.invoice_subscription[0];
    let language = i18n.language;

    return (
        <>
            {invoice_subscription &&
                <Grid item xs={12}>
                    <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                    <VuiBox
                        component="form"
                        role="form"
                        borderRadius="inherit"
                        p="15px"
                        sx={({ palette: { secondary } }) => ({
                            backgroundColor: secondary.focus,
                        })}
                    >
                         <VuiBox
                            sx={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "none",
                            overflow: "visible",
                            py: "30px"
                        }}>
                            
                            <VuiBox
                                sx={({breakpoints}) => ({
                                [breakpoints.only("xl")]: {
                                    minHeight: "200px"
                                }
                            })}>
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <VuiTypography
                                        variant="lg"
                                        color="white"
                                        fontWeight="medium"
                                    >
                                        {invoice_subscription?.subscription_models?.[0]?.languages?.[i18n.language]?.title}
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox my={3}>
                                    <VuiTypography
                                        variant="h5"
                                        color="white"
                                    >
                                        {invoice_subscription?.prices?.languages?.[i18n.language]?.price_after_discount} {invoice_subscription?.prices?.languages?.[i18n.language]?.["price-unit"]} / {invoice_subscription?.languages?.[i18n.language]?.title}
                                    </VuiTypography> 
                                </VuiBox>

                                <CreditBalance price={invoice_subscription?.prices} />

                                <VuiBox my={4} lineHeight={0}>
                                    { invoice_subscription?.facilities?.languages?.[i18n.language] && Object.entries(invoice_subscription?.facilities?.languages?.[i18n.language]).map(([key, value, index]) => (
                                        <VuiTypography key={key} variant="button" fontWeight="regular" sx={{color: '#DDE6ED'}} lineHeight="2">
                                            {key}: {value} 
                                            <br />
                                        </VuiTypography>
                                    ))}
                                </VuiBox>

                                
                                {/* <VuiBox my={3} lineHeight={0}>
                                    { invoice_subscription?.meta?.data?.IR["has-not-others-data"] && invoice_subscription?.meta?.data?.IR["has-not-others-data"].map((item, index) => (
                                        <VuiTypography key={item} variant="button" fontWeight="regular" sx={{color: '#DDE6ED'}} lineHeight="2">
                                            <CancelIcon style={{ color: '#E31A1A', scale: "1.4", marginLeft:"5px"  }} />  {item}
                                            <br />
                                        </VuiTypography>
                                    ))}
                                </VuiBox> */}
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <VuiTypography
                                        variant="h6"
                                        color="white"
                                        lineHeight="2"
                                        fontWeight="regular"
                                    >
                                        {
                                            language == "ir" 
                                            ? 
                                                <> {trans("profile_activationDate")} {changeTimeZone(invoicesData?.created_at)} </>
                                            :
                                                <> {trans("profile_activationDate")} {new Date(invoicesData?.created_at).getFullYear() + "/" + (new Date(invoicesData?.created_at).getMonth()+1) + "/" + (new Date(invoicesData?.created_at).getDate()+1)} </>
                                        }
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <VuiTypography
                                        variant="h6"
                                        color="white"
                                        fontWeight="regular"
                                        lineHeight="2"
                                    >
                                        {
                                            language == "ir" 
                                            ? 
                                                <> {trans("profile_expiryDate")} {changeTimeZone(invoicesData?.meta?.invoice_expire)} </>
                                            :
                                                <> {trans("profile_expiryDate")} {new Date(invoicesData?.meta?.invoice_expire).getFullYear() + "/" + (new Date(invoicesData?.meta?.invoice_expire).getMonth()+1) + "/" + (new Date(invoicesData?.meta?.invoice_expire).getDate()+1)} </>
                                        }
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <VuiTypography
                                        variant="h6"
                                        color="dark"
                                        fontWeight="regular"
                                        lineHeight="2"
                                    >
                                        { parseInt((new Date(invoicesData?.meta?.invoice_expire).getTime() - new Date().getTime())/(1000*60*60*24))} {trans("profile_daysLeftToFinish")}
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                    sx={{
                                        padding: "20px"
                                    }}
                                >
                                    <VuiButton component={Link} to={"/subscriptions"} color="info" fullWidth size='medium' >
                                        {trans("profile_subscriptionRenewal")}
                                    </VuiButton> 
                                </VuiBox>
                            </VuiBox>
                        </VuiBox>
                    </VuiBox>
                    </GradientBorder>
                </Grid>
            }

            {!invoice_subscription &&
                <Grid item xs={12}>
                    <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                    <VuiBox
                        component="form"
                        role="form"
                        borderRadius="inherit"
                        p="15px"
                        sx={({ palette: { secondary } }) => ({
                            backgroundColor: secondary.focus,
                        })}
                    >
                         <VuiBox
                            sx={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "none",
                            overflow: "visible",
                            py: "30px"
                        }}>
                            
                            <VuiBox
                                sx={{
                                    minHeight: "200px",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    display:"flex",
                                    flexDirection: "column"
                            }}>

                                                    
                                <Icon 
                                    sx={{ 
                                        fontWeight: "bold", 
                                        fontSize: "65px !important", 
                                        color: "#800020 !important",
                                        marginBottom: "20px"
                                        }
                                    }>
                                        cancel
                                </Icon>

                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                >
                                    <VuiTypography
                                        variant="h6"
                                        color="white"
                                        lineHeight="2"
                                        fontWeight="regular"
                                    >
                                        {trans("profile_dontHaveActivateSubscription")}
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox 
                                    alignItems="center"
                                    justifyContent="center"
                                    display="flex"
                                    sx={{
                                        padding: "20px"
                                    }}
                                >
                                    <VuiButton component={Link} to={"/subscriptions"} color="info" fullWidth size='medium' >
                                    {trans("profile_subscriptionRenewal")}
                                    </VuiButton> 
                                </VuiBox>
                            </VuiBox>
                        </VuiBox>
                    </VuiBox>
                    </GradientBorder>
                </Grid>
            }

        </>
    );
}

export default SubscriptionRow;
