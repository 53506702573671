import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from 'react-i18next';
import "driver.js/dist/driver.css";
import { driver } from 'driver.js';


function RankMainPage({loggedInUser}) {

	const { t: trans, i18n } = useTranslation();
 
    // useEffect(() => {
    
    //     const driverObj = driver( 
    //       {
    //         // overlayColor: '#1F2544',
    //         steps: [
    //           { element: '.website_management', popover: { title: 'Animated Tour Example', description: 'Here is the code example showing animated tour. Let\'s walk you through it.', side: "bottom", align: 'center' }},
    //           {
    //             element: '.keyword_management',
    //             popover: {
    //               title: 'First Step',
    //               description: 'This is the first step. Next element will be loaded dynamically.',
    //             },
    //           },
    //           {
    //             element: '.statistics',
    //             popover: {
    //               title: 'Async Element',
    //               description: 'This element is loaded dynamically.'
    //             },
    //           },
    //         ]
    //       }
    //     )
    
    //     driverObj.drive()
    
    // }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid minHeight={'100vh'} container px="15px" display="flex" justifyContent="center" alignItems="center" >
               
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card sx={{padding: "80px 0px"}}>
                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h6" fontWeight="regular">
                                {trans("rank_management_of_ranking_and_specific_information")}
                            </VuiTypography>

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">
                                
                                <Grid item xs={12} md={6} px={5}>
                                    <VuiButton
                                        className="statistics"
                                        component={Link}
                                        to={'/rank/statistics'}
                                        rel="noreferrer"
                                        size="small"
                                        sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                                            color: `${white.main} !important`,
                                            height: "100%",
                                            m: 2,
                                            py: "32px",
                                            background: `linear-gradient(to right, #000428, #004e92)`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "50%",
                                            "&:hover": {
                                                background: `linear-gradient(to right, #000428, #004e92)`
                                            },
                                        })}
                                        fullWidth
                                    >
                                        <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h6" fontWeight="regular">
                                            {trans("rank_analysis_and_ranking_information_of_sites")}
                                        </VuiTypography>

                                    </VuiButton>
                                </Grid>
                                
                                <Grid item xs={12} md={6} px={5}>
                                    <VuiButton
                                        className="website_management" 
                                        component={Link}
                                        to={'/rank/website-management'}
                                        rel="noreferrer"
                                        size="small"
                                        sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                                            color: `${white.main} !important`,
                                            height: "100%",
                                            m: 2,
                                            py: "32px",
                                            background: `linear-gradient(to right, #004e92, #000428)`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "50%",
                                            "&:hover": {
                                                background: `linear-gradient(to right, #004e92, #000428)`
                                            },
                                        })}
                                        fullWidth
                                    >
                                    
                                        <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h6" fontWeight="regular">
                                            {trans("rank_management_of_websites_for_crawling")}
                                        </VuiTypography>
                                    </VuiButton>
                                </Grid>

                                <Grid item xs={12} md={6} px={5}>
                                    <VuiButton
                                        className="keyword_management" 
                                        component={Link}
                                        to={'/rank/keyword-management'}
                                        rel="noreferrer"
                                        size="small"
                                        sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                                            color: `${white.main} !important`,
                                            height: "100%",
                                            m: 2,
                                            py: "32px",
                                            background: `linear-gradient(to right, #004e92, #000428)`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "50%",
                                            "&:hover": {
                                                background: `linear-gradient(to right, #004e92, #000428)`
                                            },
                                        })}
                                        fullWidth
                                    >
                                    
                                        <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h6" fontWeight="regular">
                                            {trans("rank_management_of_Keyword_for_crawling")}
                                        </VuiTypography>
                                    </VuiButton>
                                </Grid>
                                
                            </Grid>
                        </VuiBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default RankMainPage;
