import React from "react";
import {Card, Stack} from "@mui/material";
import balance from "assets/images/billing-background-balance.png";
import Graph from "assets/images/shapes/graph-billing.svg";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import AlarmOnIcon from '@mui/icons-material/AlarmOn';// React icons
import gif from "assets/images/card-background-2.png";
import { useTranslation } from "react-i18next";

const CreditBalance = ({price}) => {
	const { t: trans, i18n } = useTranslation();
    // console.log("props", props);
    return (
        <Card sx={{
            padding: "30px",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)),url(${gif})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
        }}>
            <VuiBox display="flex" flexDirection="column">
                <VuiBox
                    mb="32px"
                    p="20px"
                    display="flex"
                    flexDirection="column"
                    sx={{
                    backgroundImage: `url(${balance})`,
                    backgroundSize: "cover",
                    borderRadius: "18px"
                }}>
                    <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">

                        <VuiBox display="flex" justifyContent="space-beetween" alignItems="center" flexDirection="column" mr="auto">
                            <VuiTypography variant="h4" color="white" fontWeight="bold" mr="auto">
                                {price?.languages?.[i18n.language]?.price_after_discount}
                            </VuiTypography>
                            <VuiTypography variant="h6" sx={{fontSize: '13px'}} color="text" fontWeight="bold" mr="auto">
                                {price?.languages?.[i18n.language]?.["price-unit"]}
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox
                            component="img"
                            src={Graph}
                            sx={{
                            width: "58px",
                            height: "20px"
                        }}/>
                    </VuiBox>
                </VuiBox>

                {
                    price?.is_discount == "true" && 

                    <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
                        <Stack direction="row" spacing="10px" mr="auto">
                            <VuiBox
                                display="flex"
                                mr="10px"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                background: "rgba(34, 41, 78, 0.7)",
                                borderRadius: "50%",
                                width: "38px",
                                height: "38px"
                            }}>
                                <AlarmOnIcon sx={{color: '#1B9C85', scale: "1.2"}}/>
                            </VuiBox>
                            <VuiBox display="flex" flexDirection="column">
                                <VuiTypography color="white" variant="button" >
                                    {trans("subscription_card_realPrice")} {price?.["real-price"]} {price?.languages?.[i18n.language]?.["price-unit"]}
                                </VuiTypography>
                                <VuiTypography sx={{fontSize: '10px'}} color="text" variant="button" mt="5px">
                                    {trans("subscription_card_discountPrice")} {price?.price_after_discount} {price?.languages?.[i18n.language]?.["price-unit"]}
                                </VuiTypography>
                            </VuiBox>
                        </Stack>
                        
                    </VuiBox>   
                }
                
            </VuiBox>
        </Card>
    );
};

export default CreditBalance;
