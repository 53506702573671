import PropTypes from "prop-types";

function PaidSVG({ size, toggleBorder }) {
    return (
        

        <svg id="a4efc002-80f6-4f30-a290-092c25b18b21" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="100%" height="230" viewBox="0 0 1149 784.07921">
            <title>پرداخت موفق</title>
            <g>
                <path d="M460.5,805.0396c0,20.43-95.81,37-214,37s-214-16.57-214-37c0-5.11,5.98-9.97,16.8-14.39,31.5-12.89,104.03-22.05,189.28-22.58q3.945-.02994,7.92-.03C364.69,768.0396,460.5,784.60961,460.5,805.0396Z" transform="translate(-25.5 -57.9604)" fill="#3f3d56" />
                <path d="M238.58,768.06957a121.49608,121.49608,0,0,1-189.28,22.58C80.8,777.75957,153.33,768.5996,238.58,768.06957Z" transform="translate(-25.5 -57.9604)" opacity="0.1" />
            </g>
            <g>
                <ellipse cx="112" cy="628.07921" rx="112" ry="120" fill="#702963" class="animated shine infinite" />
            </g>
            <g>
                <path d="M274.92695,141.29722c15.8799,61.17672,67.10876,100.71792,114.5379,88.40654" transform="translate(-25.5 -57.9604)" fill="#5D3FD3" />
                <path d="M389.46485,229.70376c-16.05832-61.86409,13.77629-123.041,66.70446-136.77977" transform="translate(-25.5 -57.9604)" fill="green" />
                <path d="M307.45641,138.77285c15.08422,58.11138,51.76377,98.78165,82.00844,90.93091" transform="translate(-25.5 -57.9604)" fill="green" />
                <path d="M389.46485,229.70376c-20.519-79.04856,12.38076-155.86347,73.55748-171.74336" transform="translate(-25.5 -57.9604)" fill="#5D3FD3" />
            </g>
            <g>
                <rect x="343" y="126.07921" width="806" height="467" fill="#CCCCFF" />
                <rect x="362" y="267.82921" width="442" height="231.5" fill="#ff0000" opacity="0.3" />
            </g>
            <g>
                <rect x="838" y="257.7371" width="177.2807" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="275.84236" width="258" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="319.59675" width="177.2807" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="392.0178" width="177.2807" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="337.70201" width="258" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="355.80728" width="258" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="373.91254" width="258" height="9.80702" fill="#5D3FD3" class="animated shine infinite" />
                <rect x="838" y="435.77219" width="92.78947" height="25.64912" fill="#5D3FD3" />
            </g>
            <g>
                <path d="M339.98755,465.874s16.65689-47.19452,28.68687-32.3884S350.16676,473.277,350.16676,473.277Z" transform="translate(-25.5 -57.9604)" fill="#a0616a" />
                <path d="M261.33,419.60481l10.02028,3.71358s4.78585,21.27176,2.93508,24.97329-2.77615.92538,0,8.32844,11.10459,26.8361,12.03,29.61225,53.67219-17.58227,53.67219-27.76148c0,0,11.1046,12.95535,16.65689,15.7315,0,0-61.07526,44.41837-69.4037,49.97067s-12.95536,2.77615-20.35842-3.70153-38.86607-60.14988-38.86607-60.14988Z" transform="translate(-25.5 -57.9604)" fill="#5D3FD3" />
                <path d="M288.16612,748.11568s-.92538,10.17921-2.77615,11.10459-15.7315,24.05995,0,28.68686,24.98534,2.77615,28.68687,4.62692,68.47832,5.55229,69.4037-4.62692-31.463-12.03-31.463-12.03-29.61225-19.433-34.23916-29.61225S288.16612,748.11568,288.16612,748.11568Z" transform="translate(-25.5 -57.9604)" fill="#2f2e41" />
                <path d="M100.31343,766.62333S78.10425,804.564,83.65654,809.19094s59.62519,21.48848,67.55294,22.20918c10.17921.92538,17.58227-7.40306,9.25383-12.03s-25.91072-24.05995-25.91072-30.53762V772.17563Z" transform="translate(-25.5 -57.9604)" fill="#2f2e41" />
                <path d="M79.02963,518.62076s-19.433,24.05995,11.10459,62.926l50.89605,64.77679s-31.463,49.97066-32.3884,75.88138-7.40306,20.35842-9.25382,31.463-10.17921,5.5523-3.70153,13.88074,30.53763,15.73151,37.94069,9.25383,7.40306-14.80612,7.40306-18.50765,12.95536-24.98534,13.88074-31.463,35.16454-59.22449,35.16454-59.22449,11.1046-31.463-1.85076-49.04528-21.51631-42.51892-21.51631-42.51892,38.1732,46.22045,65.0093,57.325c0,0,10.17921,1.85076,8.32844,6.47768s0,7.40306,2.77615,12.03,22.20918,58.29911,28.68686,72.17986,3.70153,22.20918,11.1046,26.83609,40.71683.92539,40.71683-3.70153-4.62691-16.65689-7.40306-23.13456-24.98533-81.43368-23.13456-94.389-.92539-30.53763-14.80613-37.94069-84.20983-67.55294-87.91136-67.55294S79.02963,518.62076,79.02963,518.62076Z" transform="translate(-25.5 -57.9604)" fill="#2f2e41" />
                <circle cx="239.53155" cy="276.5092" r="38.86607" fill="#a0616a" />
                <path d="M233.56854,338.17113s-12.03,21.28381-26.8361,24.06,38.86608,45.34376,38.86608,45.34376,12.95535-40.71684,24.06-42.56761S233.56854,338.17113,233.56854,338.17113Z" transform="translate(-25.5 -57.9604)" fill="#a0616a" />
                <path d="M271.50923,425.15711c0,12.03-36.08992,46.26913-36.08992,46.26913l-34.23916,28.68687s-.92539,10.17921-4.62692,11.10459-8.32844.92538-4.62691,6.47768,12.95536,17.58227,4.62691,19.433a68.02832,68.02832,0,0,1-7.57883.72177c-2.221.12956-4.83047.24987-7.76408.36091-6.84775.24061-15.4168.39791-24.69842.33312q-3.95614-.01389-8.05078-.09251c-34.98877-.694-75.52058-4.71949-72.20764-17.98018,5.5523-22.20919,1.85077-29.61225,13.88074-41.64222s31.463-53.6722,31.463-53.6722,38.86608-65.70217,71.25447-65.70217l6.72755-1.76752a15.40619,15.40619,0,0,1,16.81413,6.45921c6.49621,9.89235,16.74026,24.754,22.72746,29.54747,9.25382,7.40306,10.69748,2.09137,10.69748,2.09137S271.50923,413.12713,271.50923,425.15711Z" transform="translate(-25.5 -57.9604)" fill="#5D3FD3" />
                <path d="M189.15017,565.81528s26.8361,41.64222,10.17921,49.04528-25.91072-43.493-25.91072-43.493Z" transform="translate(-25.5 -57.9604)" fill="#a0616a" />
                <path d="M239.62313,330.07964c1.49163-.18823,1.96338-2.07034,2.21445-3.5527,1.34448-7.93817,7.26028-15.4657,15.20887-16.74716a21.53482,21.53482,0,0,1,9.06163.73028,34.38206,34.38206,0,0,1,11.627,5.72293c1.8356,1.38836,3.60212,3,5.81038,3.64852,1.4584.42831,7.65073,3.18129,9.09759,3.6471,3.18107,1.02413,5.81348,4.35366,9.08966,3.6944,3.13435-.63071,4.32474-4.41557,4.82851-7.5728,1.14768-7.19282-2.96937-17.92518-7.06272-23.95-3.10629-4.572-8.36282-7.20324-13.61849-8.915a97.8475,97.8475,0,0,0-18.88745-3.753c-8.645-1.021-17.59876-1.56268-25.88354,1.10952s-15.82851,9.209-17.37513,17.77557c-.32218,1.7845-.38254,3.61229-.77737,5.38213-.96316,4.31751-3.81984,7.94662-5.48621,12.0444a23.19276,23.19276,0,0,0,.70753,18.80183c2.30751,4.64043,6.32027,9.09971,5.45427,14.20932l7.14107-5.63086a5.18636,5.18636,0,0,0,2.38361-6.07029l-1.03811-8.94161c-.19008-1.63724-.35562-3.404.46319-4.83437C235.75578,321.33353,237.1361,330.39345,239.62313,330.07964Z" transform="translate(-25.5 -57.9604)" fill="#2f2e41" />
                <path d="M172.49328,501.96387l8.717,36.24723c-8.79114.31465-20.423.48122-32.7492.24061-3.10939-9.32788-6.07983-19.05366-8.35623-28.15939-8.32845-33.31378,5.55229-84.20983,12.95536-108.26978S178.971,371.48491,178.971,371.48491s9.25383,2.77615,21.2838,11.10459S172.49328,501.96387,172.49328,501.96387Z" transform="translate(-25.5 -57.9604)" opacity="0.1" />
                <path d="M179.89634,500.11311l9.07806,37.73708c-2.221.12956-4.83047.24987-7.76408.36091-6.84775.24061-15.4168.39791-24.69842.33312-3.3405-9.91081-6.5703-20.36768-9.004-30.10267-8.32845-33.31378,5.5523-84.20982,12.95536-108.26978s23.59726,2.31346,23.59726,2.31346-6.47768-15.7315,5.55229-7.40306S179.89634,500.11311,179.89634,500.11311Z" transform="translate(-25.5 -57.9604)" opacity="0.1" />
                <path d="M182.67249,369.63415s-18.50765,6.47767-25.91071,30.53762-21.28381,74.956-12.95536,108.26978,25.91071,74.956,25.91071,74.956,17.58228-12.95536,24.06-10.17921l-17.58227-73.10523s39.79146-111.04593,27.76148-119.37437S182.67249,369.63415,182.67249,369.63415Z" transform="translate(-25.5 -57.9604)" fill="#5D3FD3" />
                <rect x="343" y="243.82921" width="442" height="231.5" fill="#3f3d56" />
            </g>
            <g>
                <ellipse cx="572.22317" cy="364.11087" rx="50.01735" ry="50.10831" fill="#fff" />
                <path d="M597.72317,363.42341A59.77683,59.77683,0,1,0,657.5,423.20024,59.95257,59.95257,0,0,0,597.72317,363.42341Zm-12.27149,91.64822L554.81605,424.436l8.59292-8.59291,22.07145,22.07145,46.58569-46.5857,8.59292,8.59292-55.20735,55.14987Z" transform="translate(-25.5 -57.9604)" fill="green" />
            </g>
        </svg>
        
        
    );
}

PaidSVG.defaultProps = {
  color: "dark",
  size: "16px",
};

PaidSVG.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PaidSVG;
