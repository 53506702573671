import VuiBox from "components/VuiBox";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import SubscriptionCard from "./subscriptionColoumn";

function SubscriptionRow({subscriptions}) {

    return (
        <>
            {subscriptions?.map( ( item ) => (
                <Grid key={item?.id} item xs={12} md={6} xl={4}>
                    <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                    <VuiBox
                        component="form"
                        role="form"
                        borderRadius="inherit"
                        p="15px"
                        sx={({ palette: { secondary } }) => ({
                            backgroundColor: secondary.focus,
                        })}
                    >
                        <SubscriptionCard    
                            subscription={item}
                        />
                    </VuiBox>
                    </GradientBorder>
                </Grid>
            ))}
        </>
    );
}

export default SubscriptionRow;
