import React, { useEffect, useState } from 'react';
import { Card, Stack, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import gif from "assets/images/card-background-2.png";
import { changeTimeZone } from 'utilities/Utils';
import { useTranslation } from 'react-i18next';

function SubscriptionTimeManagement({loggedInUser, lastInvoice}) {

	const { t: trans, i18n } = useTranslation();
	const { info, gradients } = colors;
	const { cardContent } = gradients;
	const [ createdAt, setCreatedAt ] = useState('xxxx/xx/xx')
	const [ expireAt, setExpireAt ] = useState('xxxx/xx/xx')
	const [ theDayToExpire, setTheDayToExpire ] = useState(0)
	const [ progressColor, setProgressColor ] = useState("info");
	const [ progressSize, setProgressSize ] = useState(1)
	const [ sentence, setSentence ] = useState(trans("publicMessage_untilEndOfSubscriptions"));
	let language = i18n?.language;
	
	useEffect( () => {

		if(lastInvoice){
			let t = new Date(lastInvoice?.meta?.invoice_expire);
			if(i18n?.language === "ir"){
				setCreatedAt(changeTimeZone(lastInvoice?.created_at));
				setExpireAt(changeTimeZone(t));
			}else{
				setCreatedAt(new Date(lastInvoice?.created_at).getFullYear() + "/" + (new Date(lastInvoice?.created_at).getMonth()+1) + "/" + (new Date(lastInvoice?.created_at).getDate()+1));
				setExpireAt(new Date(t).getFullYear() + "/" + (new Date(t).getMonth()+1) + "/" + (new Date(t).getDate()+1));
			}
		}
		

		let dayToExpire = parseFloat(lastInvoice?.meta?.invoice_expire - new Date().getTime()) ;
		if(dayToExpire){
			setTheDayToExpire(parseInt(dayToExpire/(24*60*60*1000)));
		}

		if(parseInt(dayToExpire/(24*60*60*1000))){
			if(parseInt(dayToExpire/(24*60*60*1000)) < 0){
				setTheDayToExpire(0);
				setSentence(trans("publicMassage_finishedSubscriptions"))
				setProgressSize(100);
				setProgressColor("error");
			}else{
				
				let daySpent = parseInt(lastInvoice?.meta?.subscription_expire) - parseInt(dayToExpire/(24*60*60*1000));
				let size = ((daySpent - parseInt(lastInvoice?.meta?.subscription_expire)) / parseInt(lastInvoice?.meta?.subscription_expire)) * 100;
				setProgressSize(size + 100);
				setProgressColor("info");
			}
		}

	},[lastInvoice])

	return (
		<Card
			sx={{
				height: '100%',
				m:2,
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)),url(${gif})`,
				backgroundSize: "cover",
				backgroundPosition: "50%",
			}}>
			<Grid container sx={{ width: '100%' }}>
				<Grid
					xs={12}
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='40px'>
					<VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
						{trans("dashboard_card_subscriptionsManagement")}
					</VuiTypography>
					
				</Grid>

				<Grid
					display='flex'
					container
					sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}
				>
					
					<Grid xs={12} sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
						<CircularProgress
							variant='determinate'
							value={progressSize}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color={progressColor}
						/>
						<VuiBox
							sx={{
								
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								
								<VuiTypography
									color='white'
									variant='d5'
									fontWeight='bold'
									mb='4px'
									sx={({ breakpoints }) => ({
										[breakpoints.only('xl')]: {
											fontSize: '32px'
										}
									})}>
									{theDayToExpire}
								</VuiTypography>
								
								{
									theDayToExpire != 0 && 
									<>
										<VuiTypography color='text' variant='button'>
											{trans("publicMessage_untilEndOfSubscriptions")}
										</VuiTypography>
									</>
								}
								
							</VuiBox>
						</VuiBox>
						
					</Grid>

					{
						theDayToExpire == 0 && 
						<VuiTypography color='text' variant='button' my='10px'>
							{sentence}
						</VuiTypography>
					}
					
					<Grid 
						container 
						m='20px 0px'
						p='20px 22px'
						sx={({ breakpoints }) => ({
						background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
						borderRadius: '20px',
					})}>
						<Grid
							xs={6}
							display='flex'
							width='95%'
							flexDirection='column'
						>
							<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								{trans("dashboard_card_subscriptionsManagementBought")}
							</VuiTypography>
							<VuiTypography color='white' variant='lg' fontWeight='bold'>
							{ createdAt }
							</VuiTypography>
						</Grid>
						<Grid
							display='flex'
							width='100%'
							xs={6}
							flexDirection='column'
						>
							<VuiTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								{trans("publicMassage_endSubscriptions")}
							</VuiTypography>
							<VuiTypography color='white' variant='lg' fontWeight='bold'>
								{expireAt}
							</VuiTypography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
}

export default SubscriptionTimeManagement;
