import Icon from "@mui/material/Icon";
import VuiButton from "components/VuiButton";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { Link } from "react-router-dom";
import borders from "assets/theme/base/borders";
import FailedSVG from "examples/Icons/FailedPayment";
import { useTranslation } from "react-i18next";


const PaymentFailed = ()=> {

	const { t: trans, i18n } = useTranslation();

    return(
        <Grid item xs={12} md={8} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <VuiBox
                sx={{
                    height: "100px",
                    width: "100px",
                    background: "red",
                    borderRadius: "100%",
                    position: "relative",
                    top: "50px",
                    border: "white 1px solid"
                }}
                display="flex" justifyContent="center" alignItems="center"
            >
                <Icon sx={{ fontWeight: "bold", fontSize: "65px !important", color: "white !important"}}>close</Icon>
            </VuiBox>

            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="15px"
                    pt="100px"
                    sx={({ palette: { dark } }) => ({
                        backgroundColor: "#070B2B",
                    })}
                >
                <Grid container spacing={4} pt={3} display="flex" justifyContent="center" alignItems="center">

                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <VuiTypography variant="h5" color="white">
                            {trans("publicMassage_failPayment")}
                        </VuiTypography>
                    </Grid>
                    
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <VuiTypography variant="h6" color="white">
                         {trans("payment_paid_regretMessage")}
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <FailedSVG />
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <VuiButton component={Link} to={"/dashboard"} color="info" fullWidth size='medium'>
                            {trans("publicMassage_dashboard")}
                        </VuiButton>
                    </Grid>
                </Grid>
                </VuiBox>
            </GradientBorder>
        </Grid>
    )

};

export default PaymentFailed;
