import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { changeTimeZone } from "utilities/Utils";

const DashboardPaymentColoumns = (trans, language) => {

  return {
    columns: [
      { name: "subscription_name", align: "left", title: trans("publicTable_susbscription") },
      { name: "amount", align: "left", title: trans("publicTable_price") },
      { name: "subscription_expire", align: "left", title: trans("publicTable_susbscriptionDuration") },
      { name: "invoice_created", align: "center", title: trans("publicTable_purchaseDate") },
    ],
  }
}

const DashboardPaymentDataTable = (invoicesData, trans, language) => {

  let rows = [];

    invoicesData.forEach(result => {
      if(result?.id){
          let row = {
            subscription_name: (
              <VuiBox display="flex" alignItems="center" sx={{paddingTop: '10px'}}>
                <VuiTypography pl="10px" color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                  {trans("publicTable_susbscription")} {result?.invoice_subscription?.[0]?.languages?.[language]?.title} {result?.invoice_subscription?.[0]?.subscription_models?.[0]?.languages?.[language]?.title}
                </VuiTypography>
              </VuiBox>
            ),
            amount: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.amount} 
              </VuiTypography>
            ),
            subscription_expire: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.meta?.subscription_expire} {trans("publicMassage_day")}
              </VuiTypography>
            ),
            invoice_created: (
              <VuiTypography color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                {
                  language == "ir" 
                  ? 
                    <> {changeTimeZone(result?.created_at)} </>
                  :
                    <> {new Date(result?.created_at).getFullYear() + "/" + (new Date(result?.created_at).getMonth()+1) + "/" + (new Date(result?.created_at).getDate()+1)} </>
                }
                
              </VuiTypography>
            ),
           
          }
          rows.push(row);
        }
    });
  

  return {rows}
}

export {DashboardPaymentColoumns, DashboardPaymentDataTable};