
import Grid from "@mui/material/Grid";
import { Card, LinearProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import SubscriptionTimeManagement from "layouts/dashboard/components/SubscriptionTimeManagement";
import KeywordsScraperManagement from "./components/KeywordsScraperManagement";

import Header from "layouts/profile/components/Header";
import { useContextUIController } from "context";
import { useQuery } from "@apollo/client";
import Table from "examples/Tables/Table";
import { gql } from "@apollo/client/core";
import { DashboardPaymentColoumns, DashboardPaymentDataTable } from "./components/table/data/DataTable";
import { useTranslation } from 'react-i18next';

const QUERY_INVOICES = gql`
query ($user_id: uuid!) {
    invoices(where: {user_id: {_eq: $user_id}, payment_status: {_eq: "paid"}}, order_by: {created_at: desc}) {
      amount
      created_at
      id
      meta
      payment_status
      invoice_subscription {
        subscription_models {
          languages
        }
        languages
      }
    }
  }
`;

function Dashboard({loggedInUser}) {

    const {data: invoicesData} = useQuery(QUERY_INVOICES, {variables: {user_id: loggedInUser?.id}, notifyOnNetworkStatusChange: true});
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(null);
    const [ lastInvoice, setLastInvoice ] = useState(null);
	const { t: trans, i18n } = useTranslation();
    
    
    useEffect(() => {
        
        const fetchData = async () => {
            if (invoicesData) {
                let c = await DashboardPaymentColoumns(trans, i18n.language);
                setColumns(c.columns);
                let s = await DashboardPaymentDataTable(invoicesData?.invoices, trans, i18n.language);
                setRows(s.rows);
                setLastInvoice(invoicesData?.invoices?.[0]);
            }
        };
    
        fetchData();
    
    }, [invoicesData, i18n.language]);
    return (
        <DashboardLayout>
            <Header loggedInUser={loggedInUser} />
            <VuiBox py={3}>

                <VuiBox mb={3}>
                    <Grid container spacing="18px">
                        <Grid item xs={12} lg={12} xl={4}>
                            <WelcomeMark loggedInUser={loggedInUser} />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <KeywordsScraperManagement loggedInUser={loggedInUser} />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <SubscriptionTimeManagement lastInvoice={lastInvoice} loggedInUser={loggedInUser} />
                        </Grid>
                    </Grid>
                </VuiBox>
                <VuiBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} xl={7}>
                            <Card sx={{width: "100%"}}>
                                <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                    <VuiTypography variant="lg" color="white">
                                        {trans("publicTable_successfulPurchase")}
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBox
                                    sx={{
                                    "& th": {
                                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                        `${borderWidth[1]} solid ${grey[700]}`,
                                    },
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                            `${borderWidth[1]} solid ${grey[700]}`,
                                        },
                                    },
                                    }}
                                >
                                {
                                    columns && <Table columns={columns} rows={rows} />
                                }
                               
                                </VuiBox>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={5}>
                            
                        </Grid>
                    </Grid>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default Dashboard;
