
const CategoryColoumns = () => {
  return {
    columns: [
      { name: "category_name", align: "left", persianName: 'نام دسته بندی' },
      { name: "category_items", align: "left", persianName: 'محتوای دسته بندی' },
      { name: "delete_category", align: "left", persianName: 'حذف دسته بندی' }
    ],
  }
}

export {CategoryColoumns};