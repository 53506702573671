import React,{ useState, useEffect } from "react";
import { FormControlLabel, Checkbox, Grid, Typography, Radio, RadioGroup } from '@mui/material';
import '../style/index.css';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import Divider from "@mui/material/Divider";
import {gql, useMutation, useQuery} from '@apollo/client';
import { useTranslation } from "react-i18next";

const QUERY_CATEGORY = gql`
query ($user_id: uuid!) {
    suggestions_category(where: {category_users: {_eq: $user_id}}) {
      category_items
      category_name
      category_users
      id
    }
  }
`;

export default function Conditions({handleOnChanged, handleOnChangedArray, loggedInUser}) {

    const [ formState, setFormState ] = useState({
        persian_alphabet: false,
        english_alphabet: false,
        numbers: false,
        dash: false,
        hyphen: false,
    });
    const [otherCategories, setOtherCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [query, setQuery] = useState(QUERY_CATEGORY);
    const [queryVars, setQueryVars] = useState({user_id: loggedInUser?.id});
    const {loading, error, data, refetch} = useQuery(query, {variables: queryVars, notifyOnNetworkStatusChange: true});
	const { t: trans, i18n } = useTranslation();
    
    const handleChangedDefault = (e) => {
        setFormState(s => ({...s, [e.target.value]: e.target.checked}));
    }

    const handleChangedOwnCategory = (e) => {
        
        const categoryValue = e.target.value;

        if(e.target.checked){
            setSelectedCategories((prevSelected) => [...prevSelected, categoryValue]);
        }else{
            setSelectedCategories((prevSelected) =>
                prevSelected.filter((category) => category !== categoryValue)
            );
        }
    }

    useEffect( () => {
        const fetchData = async () => {

            if(data) setOtherCategories(data?.suggestions_category)
        }
        fetchData()
    },[data]);

    useEffect( () => {
        
        const fetchData = async () => {

            let category = [];
            let special_sign = [];

            if(formState?.persian_alphabet) category.push('persian_alphabet')
            if(formState?.english_alphabet) category.push('english_alphabet')
            if(formState?.numbers) category.push('numbers');

            if(formState?.dash) special_sign.push('dash');
            if(formState?.hyphen) special_sign.push('hyphen');

            let special_sign_value = special_sign.toString();
            let category_value = category.toString();
            
            if(selectedCategories?.length > 0){
                if(category_value == ''){
                    category_value = selectedCategories.toString();
                }else{
                    let tmp = selectedCategories.toString();
                    let newStr = category_value + ',' + tmp;
                    category_value = newStr;
                }
            }

            handleOnChangedArray('special_sign', special_sign_value);
            handleOnChangedArray('category', category_value);
        }
        fetchData()
    },[formState,selectedCategories]);   

    return (
            <>
                <Grid item sm={6} xs={12}>
                    <VuiBox mb="4px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_chooseCategory")}
                        </VuiTypography>
                    </VuiBox>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Typography variant="body1" component="span"><Checkbox name="category" value="persian_alphabet" onChange={handleChangedDefault} /></Typography>}
                                label="الف-ی"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Typography variant="body1" component="span"><Checkbox name="category" value="english_alphabet" onChange={handleChangedDefault} /></Typography>}
                                label="A-Z"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Typography variant="body1" component="span"><Checkbox name="category" value="numbers" onChange={handleChangedDefault} /></Typography>}
                                label="0-9"
                            />
                        </Grid>
                        {
                            otherCategories &&
                            otherCategories.map( (value) =>(
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Typography variant="body1" component="span"><Checkbox name="category" value={value?.id} onChange={handleChangedOwnCategory} /></Typography>}
                                        label={value?.category_name}
                                    />
                                </Grid>
                            ))
                            
                        }
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <VuiBox mb="4px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_positionOfCategory")}
                        </VuiTypography>
                    </VuiBox>
                    <RadioGroup row>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={trans("publicMassage_begnning")}
                                    name="category_pos"
                                    value="at_first"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <FormControlLabel
                                    control={<Radio />}
                                    label={trans("publicMassage_End")}
                                    name="category_pos"
                                    value="at_end"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>

                <Divider variant="middle" />

                <Grid item sm={6} xs={12}>
                    <VuiBox mb="4px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_specificSymptoms")}
                        </VuiTypography>
                    </VuiBox>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Typography variant="body1" component="span"><Checkbox name="special_sign" value="dash" onChange={handleChangedDefault} /></Typography>}
                                label="Dash"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Typography variant="body1" component="span"><Checkbox name="special_sign" value="hyphen" onChange={handleChangedDefault} /></Typography>}
                                label="Hyphen"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <VuiBox mb="4px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_positionOfSpecificSymptoms")}
                        </VuiTypography>
                    </VuiBox>
                    <RadioGroup row>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_beginningWithSpacing")}
                                    name="special_sign_pos"
                                    value="first-space"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_beginningWithOutSpacing")}
                                    name="special_sign_pos"
                                    value="first-nonespace"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_endWithSpacing")}
                                    name="special_sign_pos"
                                    value="end-space"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_endWithOutSpacing")}
                                    name="special_sign_pos"
                                    value="end-nonespace"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
                
                <Divider variant="middle" />


                <Grid item sm={6} xs={12}>
                    <VuiBox mb="4px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_keywordSituation")}
                        </VuiTypography>
                    </VuiBox>
                    <RadioGroup row>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_withSpacing")}
                                    name="keyword_space"
                                    value="space"
                                    onChange={handleOnChanged}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            <FormControlLabel
                                    control={<Radio />}
                                    label={trans("suggestions_withOutSpacing")}
                                    name="keyword_space"
                                    value="none_space"
                                    onChange={handleOnChanged}  
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </>
    );
}