import React from "react";
import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/body-background.png";
import { useTranslation } from "react-i18next";


const WelcomeMark = ({loggedInUser}) => {

  const { t : trans } = useTranslation();


  return (
    <Card sx={() => ({
      height: "100%",
      m: 2,
      py: "32px",
      backgroundImage: `url(${gif})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
    })}>
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="white" variant="h4" fontWeight="regular" mb="20px">
            {trans("dashboard_card_welcomeMark")}
          </VuiTypography>
          <VuiTypography color="white" variant="h6" fontWeight="regular" mb="auto" sx={{padding: "35px 0px"}}>
           {loggedInUser?.fullname} {trans("dashboard_card_welcomeMarkContent")}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
