import React, {
    useState,
    useEffect
} from 'react';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client/core";
import {useMutation, useQuery} from '@apollo/client';
import { SuccessAlarm ,ErrorAlarm} from "examples/Alert";
import Loader from "examples/loader";
import { useTranslation } from 'react-i18next';


const ACTION_SEND_CODE = gql`
  mutation ($phoneNumber: String!, $action: String!) {
      action_send_code(phoneNumber: $phoneNumber, action: $action) {
        success
        alert
      }
  }  
`;

const ResendAuthenticationCode = ({phoneNumber, action, setConfirmationCode, setFormState}) => {

    const initialTimerValue = parseInt(process.env.REACT_APP_AUTH_RESEND_CODE);
    const [timer, setTimer] = useState(initialTimerValue);
    const [canResend, setCanResend] = useState(false);
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = React.useState('');
    const [loader, setLoader] = React.useState(false);
	const { t: trans, i18n } = useTranslation();

    const [doSendCode,] = useMutation(ACTION_SEND_CODE);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setCanResend(true);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const sendVerificationCode = async (phoneNumber, action) => {

        setLoader(true);
        setErrorAlert(false);
    
        try {
            const result = await doSendCode({variables: {phoneNumber, action} });
            const success = result?.data?.action_send_code?.success;
        
            setLoader(false);
        
            if(success){
                setFormState(s => ({...s, phoneNumber: phoneNumber}));    
                setConfirmationCode(true)
            }else{
                setErrorAlertMsg(result?.data?.action_send_code?.alert?.msg);
                setErrorAlert(false);
                setErrorAlert(true);
            }
        } catch (e) {
            console.log(e);
            setErrorAlertMsg(e?.message);
            setErrorAlert(false);
            setErrorAlert(true);
        }
    };

    const handleResendCode = () => {
       
        sendVerificationCode(phoneNumber, action)
        console.log('Resending authentication code...');
        setTimer(initialTimerValue);
        setCanResend(false);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return ( 
    <>
        {
            loader && <Loader setLoader={loader} />
        }
        {
            errorAlert && <ErrorAlarm openAlarams={errorAlert} alarmProps={{msg: errorAlertMsg}} />
        }
        <VuiBox mt={3} textAlign="center">
            {
                canResend && 
                <VuiBox mt={3} textAlign="center">
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                        {trans("Authentication_publicMessage_receivedAuthCode")} {" "}
                        <VuiTypography
                            component={Link}
                            onClick={handleResendCode}
                            variant="button"
                            color="white"
                            fontWeight="medium"
                        >
                            {trans("Authentication_publicMessage_resendCode")}
                        </VuiTypography>
                    </VuiTypography>
                </VuiBox>
            }
            {
                !canResend && 
                <VuiBox mt={3} textAlign="center">
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                        {trans("Authentication_publicMessage_getConfirmationCodeAgain")}  {" "}
                        <VuiTypography
                            variant="text"
                            color="white"
                            fontWeight="medium"
                        >
                        {formatTime(timer)}
                        </VuiTypography>
                    </VuiTypography>
                </VuiBox>
            }
        </VuiBox>
        </>
    );
};

export default ResendAuthenticationCode;