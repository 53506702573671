import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Google from "examples/Icons/Google";
import Youtube from "examples/Icons/Youtube";
import { changeTimeZone } from "utilities/Utils";
import { useTranslation } from "react-i18next";

const ProfileColoumns = () => {

  return {
    columns: [
      { name: "subscription_name", align: "left", persianName: 'تعرفه' },
      { name: "amount", align: "left", persianName: 'قیمت' },
      { name: "payment_status", align: "center", persianName: 'وضعیت' },
      { name: "subscription_expire", align: "left", persianName: 'مدت زمان تعرفه' },
      { name: "invoice_created", align: "center", persianName: 'تاریخ خرید' },
      { name: "invoice_expire", align: "center", persianName: 'تاریخ انقضاء' },
    ],
  }
}

const ProfileDataTable = (invoicesData, trans, language) => {

    console.log("invoicesData",invoicesData);
    let rows = [];

    invoicesData.forEach(result => {
      if(result?.id){
          let row = {
            subscription_name: (
              <VuiBox display="flex" alignItems="center" sx={{paddingTop: '10px'}}>
                <VuiTypography pl="10px" color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                  {trans("publicTable_susbscription")} {result?.invoice_subscription?.[0]?.languages?.[language]?.title} {result?.invoice_subscription?.[0]?.subscription_models?.[0]?.languages?.[language]?.title}
                </VuiTypography>
              </VuiBox>
            ),
            amount: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.amount}
              </VuiTypography>
            ),
            payment_status: (
              <>
                {
                  result?.payment_status == "paid" ? 
                    <VuiTypography color="success" fontWeight="medium"  sx={{fontSize: "15px"}}>
                      {trans("publicMassage_successfulPayment")}
                    </VuiTypography>
                  :
                  <VuiTypography color="error" fontWeight="medium"  sx={{fontSize: "15px"}}>
                    {trans("publicMassage_failPayment")}
                  </VuiTypography>
                }
              </>
            ),
            subscription_expire: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.meta?.subscription_expire} {trans("publicMassage_day")}
              </VuiTypography>
            ),
            invoice_created: (
              <VuiTypography color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                {
                    language == "ir" 
                    ? 
                        <> {changeTimeZone(result?.created_at)} </>
                    :
                        <> {new Date(result?.created_at).getFullYear() + "/" + (new Date(result?.created_at).getMonth()+1) + "/" + (new Date(result?.created_at).getDate()+1)} </>
                }
              </VuiTypography>
            ),
            invoice_expire: (
              <VuiTypography color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                {
                    language == "ir" 
                    ? 
                        <> {changeTimeZone(result?.meta?.invoice_expire)} </>
                    :
                        <> {new Date(result?.meta?.invoice_expire).getFullYear() + "/" + (new Date(result?.meta?.invoice_expire).getMonth()+1) + "/" + (new Date(result?.meta?.invoice_expire).getDate()+1)} </>
                }
              </VuiTypography>
            ),
          }
          rows.push(row);
        }
    });
  

  return {rows}
}

export {ProfileColoumns, ProfileDataTable};