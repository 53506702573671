import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import VuiInput from "components/VuiInput";
import Divider from "@mui/material/Divider";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useContextUIController } from "context";
import React, { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {gql, useMutation, useQuery} from '@apollo/client';
import Loader from "examples/loader";
import { SuccessAlarm, ErrorAlarm } from "examples/Alert";
import VuiTextAreaInput from "components/VuiTextArea";
import Table from "examples/Tables/Table";
import { CategoryColoumns } from "./table/data/CategoryDataTable";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomizedHook from "./AutoComplete";
import { useTranslation } from "react-i18next";
import Toolbar from "examples/Toolbar";

const INSERT_CATEGORY = gql`
mutation ($category_name: String!, $category_items: String!, $category_users: uuid!) {
    insert_suggestions_category(objects: {
        category_name: $category_name, 
        category_items: $category_items,
        category_users: $category_users
    }) {
    returning {
        id
    }
  }
}
`;

const QUERY_CATEGORY = gql`
query ($user_id: uuid!) {
    suggestions_category(where: {category_users: {_eq: $user_id}}) {
      category_items
      category_name
      category_users
      id
    }
  }
`;

const DELETE_CATEGORY = gql`
mutation ($category_id: uuid!) {
  delete_suggestions_category(where: {id: {_eq: $category_id }}) {
    affected_rows
  }
}
`;

function Category({loggedInUser}) {

    const [, dispatch] = useContextUIController();
    const [insertCategory,] = useMutation(INSERT_CATEGORY);
    const [loader, setLoader] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [successAlertMsg, setSuccessAlertMsg] = React.useState('');
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = React.useState('');
    
    const [query, setQuery] = useState(QUERY_CATEGORY);
    const [deleteCategory,] = useMutation(DELETE_CATEGORY);
    
    const [queryVars, setQueryVars] = useState({user_id: loggedInUser?.id});
    const {loading, error, data, refetch} = useQuery(query, {variables: queryVars, notifyOnNetworkStatusChange: true});
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
	const { t: trans, i18n } = useTranslation();

    useEffect( () => {

        const fetchData = async () => {

            if(data?.suggestions_category){
                let c = await CategoryColoumns();
                setColumns(c.columns);

                let s = await CategoryDataTable(data?.suggestions_category);
                setRows(s.rows);
            }
        }

        fetchData();
        
    }, [data]);

    const [ formState, setFormState ] = useState({
        categoryName: ''
    });

    const handleOnChanged = (e) => {
        setFormState(s => ({...s, [e.target.name]: e.target.value}));
    }

    const handleToInsertCategory = async () => {

        setLoader(true);
        setErrorAlert(false);

        try {

            if( !formState.categoryName || formState.categoryName.trim() == '' || selectedTags?.length < 1 ){
                setErrorAlert(false);
                setErrorAlert(true);
                setErrorAlertMsg('ورودی ها را چک کنید.');
                setLoader(false);

                return;
            }

            let mutationResult = await insertCategory({
                variables: {
                    category_items:     selectedTags.toString() ,
                    category_name:      formState.categoryName,
                    category_users:     loggedInUser?.id,
                    user_id:            loggedInUser?.id
                }
            });

            setLoader(false);
            
            if(mutationResult?.data?.insert_suggestions_category?.returning){
                setSuccessAlert(true);
                setSuccessAlertMsg('با موفقیت ثبت شد.');
                setFormState({
                    categoryName: ''
                })
            }else{
                setErrorAlert(true);
                setErrorAlertMsg('متاسفانه عملیات موفق نبود');
            }

            refetch();

            setSelectedTags([]);

        } catch (error) {
            setErrorAlert(true);
            console.log(error?.message);
            setErrorAlertMsg('متاسفانه عملیات موفق نبود');
        }
    }

    const handleDeleteCategory = async (categoryId) => {

        setLoader(true);
        setErrorAlert(false);

        try {

            let mutationResult = await deleteCategory({
                variables: {
                    category_id: categoryId?.target?.value,
                }
            });  

            setLoader(false);

            if(mutationResult?.data?.delete_suggestions_category?.affected_rows){
                setSuccessAlert(true);
                setSuccessAlertMsg('دسته بندی مورد نظر حذف گردید.');
                setFormState({
                    categoryName: ''
                })
            }else{
                setErrorAlert(true);
                setErrorAlertMsg('متاسفانه عملیات موفق نبود');
            }

            refetch();
            
        } catch (error) {
            setErrorAlert(true);
            console.log(error?.message);
            setErrorAlertMsg('متاسفانه عملیات موفق نبود');
        }    
    }

    const CategoryDataTable = (categoryData) => {

        let rows = [];
        
        categoryData.forEach(result => {
          let row = {
            category_name: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.category_name}
              </VuiTypography>
            ),
            category_items: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.category_items}
              </VuiTypography>
            ),
            delete_category: (
              <VuiButton onClick={handleDeleteCategory} value={result?.id} color="error" fullWidth size='medium'>
                  <DeleteIcon />
              </VuiButton>
            )
          }
      
          rows.push(row);
        });

        return {rows}
    }

    return (
        <DashboardLayout>
            
            { loader && <Loader setLoader={loader} /> }
            
            <DashboardNavbar />
            

            <Grid  px="15px" container sx={{minHeight: '100vh'}} display="flex" justifyContent="center" alignItems="center" >
                
                { successAlert && <SuccessAlarm openAlarams={successAlert} alarmProps={{msg: successAlertMsg}} /> }
                { errorAlert && <ErrorAlarm openAlarams={errorAlert} alarmProps={{msg: errorAlertMsg}} /> }
            
                <Grid item xs={12} md={11} lg={7} xl={10}>
                    <Card>
                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">
                            
                                <Toolbar backlinkUrl={'/suggestion'} />

                                <Grid item xs={12} md={7} px={5}>
                                    <VuiBox mb={3} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white"
                                                sx={{ fontSize: 15 }}
                                            >
                                                {trans("category_name")}
                                            </VuiTypography>
                                        </VuiBox>
                                        <GradientBorder
                                            minWidth="100%"
                                            borderRadius={borders.borderRadius.lg}
                                            padding="1px"
                                            backgroundImage={radialGradient(
                                            palette.gradients.borderLight.main,
                                            palette.gradients.borderLight.state,
                                            palette.gradients.borderLight.angle
                                            )}
                                        >
                                            <VuiInput
                                                value={formState.categoryName}
                                                name="categoryName"
                                                type="text"
                                                sx={({ typography: { size } }) => ({
                                                    fontSize: size.sm,
                                                })}
                                                onChange={handleOnChanged}
                                            />
                                        </GradientBorder>
                                    </VuiBox>
                                </Grid>

                                <Divider variant="middle" />
                                
                                <Grid item xs={12} md={10} px={5}>
                                    <VuiBox mb={3} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white"
                                                sx={{ fontSize: 15 }}
                                            >
                                                {trans("category_content")}
                                            </VuiTypography>
                                        </VuiBox>
                                        
                                        <CustomizedHook selectedTags={selectedTags}  setSelectedTags={setSelectedTags} />
                                        
                                    </VuiBox>
                                </Grid>
                                <Divider variant="middle" />

                                <Grid item xs={5}>
                                    <VuiBox mb={1}>
                                        <VuiButton onClick={handleToInsertCategory} color="info" fullWidth size='medium'>
                                            {trans("publicMassage_submit")}
                                        </VuiButton>
                                    </VuiBox>
                                </Grid>
                            </Grid>
                        </VuiBox>
                    </Card>
                </Grid>
            </Grid>

            {
                rows.length > 0 && 

                <VuiBox  px="15px"  py={3}>
                    <Card>
                        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <VuiTypography variant="lg" color="white">
                                {trans("category_registeredCategories")}
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox
                            sx={{
                            "& th": {
                                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                `${borderWidth[1]} solid ${grey[700]}`,
                            },
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                    `${borderWidth[1]} solid ${grey[700]}`,
                                },
                            },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </VuiBox>
                    </Card>
                </VuiBox>   
            }
             
        </DashboardLayout>
    );
}

export default Category;
