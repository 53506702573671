import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from 'react-i18next';
import MyWebsite from "./MyWebsite";
import OtherWebsite from "./OtherWebsite";
import { Divider } from "@mui/material";
import Toolbar from "examples/Toolbar";
import { gql, useMutation, useQuery } from "@apollo/client";

const INSERT_WEBSITES_RANKING = gql`
mutation ($website_name: String!, $ownership: String!, $status: String!, $user_id: uuid!, $meta: jsonb!) {
    insert_websites_ranking(objects: {
        website_name: $website_name, 
        user_id: $user_id, 
        status: $status, 
        ownership: $ownership, 
        meta: $meta
    }) {
        returning {
          id
        }
    }
}
`;

const QUERY_WEBSITES_RANKING = gql`
query ($user_id: uuid!, $status: String!) {
    websites_ranking(where: {_and: [{user_id: {_eq: $user_id}}, {status: {_eq: $status}} ]}) {
      id
      meta
      ownership
      status
      user_id
      website_name
    }
  }
`;

const UPDATE_WEBSITES_RANKING = gql`
mutation ($status: String!, $id: uuid!) {
    update_websites_ranking(where: {id: {_eq: $id}}, _set: {status: $status}) {
        affected_rows
    }
}
`;

function WebsiteManagement({loggedInUser}) {

    const [insertWebsitesRanking,] = useMutation(INSERT_WEBSITES_RANKING);
    const [updatedWebsitesRanking,] = useMutation(UPDATE_WEBSITES_RANKING);

    const {data: websites_ranking_database} = useQuery(QUERY_WEBSITES_RANKING, {variables: {user_id: loggedInUser?.id, status: 'visible'}, notifyOnNetworkStatusChange: true});

	const { t: trans, i18n } = useTranslation();
    const [ websites, setWebsites ] = useState([]);

    useEffect(() => {

        if(websites_ranking_database?.websites_ranking?.length > 0){

            websites_ranking_database?.websites_ranking?.forEach( w => {
                
                setWebsites( prev => [...prev, {website_name: w?.website_name, ownership: w?.ownership}]);
            });
        }
    },[websites_ranking_database]);
    

    const mutationPart = ({itemsToAdd, itemsToDelete}) => {

        itemsToAdd?.forEach( async ad => {

            let mutationResult = await insertWebsitesRanking({
                variables: {
                    meta: {},
                    ownership: ad?.ownership,
                    status: 'visible',
                    user_id: loggedInUser?.id,
                    website_name: ad?.website_name
                }
            });
        });

        itemsToDelete?.forEach( async ad => {

            let  mutationResult = await updatedWebsitesRanking({
                variables: {
                    id: ad?.id,
                    status: 'invisible',
                }
            });
        });
    }

    const handleInsertWebsitesRanking = async () => {

        const currentWebsites = websites; 
        const currentDB = websites_ranking_database?.websites_ranking;
        
        const itemsToAdd = currentWebsites.filter(website =>
            !currentDB?.some(dbWebsite =>
            dbWebsite.website_name === website.website_name && dbWebsite.ownership === website.ownership));
        
        const itemsToDelete = currentDB.filter(dbWebsite =>
            !currentWebsites?.some(website =>
            website.website_name === dbWebsite.website_name && website.ownership === dbWebsite.ownership));
        
        mutationPart({itemsToAdd, itemsToDelete});
    }



    return (
        <DashboardLayout>

            <DashboardNavbar />
            
            <Grid minHeight={'100vh'} container px="15px" display="flex" justifyContent="center" alignItems="center" >
               
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card sx={{padding: "80px 0px"}}>
                        <VuiBox display="flex" flexDirection="column" height="100%" display="flex" justifyContent="center" alignItems="center">

                            <VuiTypography component="label" variant="button" color="white" sx={{ fontSize: 15 }}>
                                {trans("rank_management_of_websites_for_crawling")} 
                            </VuiTypography>

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">
                                
                                <Toolbar backlinkUrl={'/rank'} />

                                <Grid item xs={12} md={6} px={5}>
                                    
                                    <VuiBox p={5} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white" sx={{ fontSize: 15 }}>
                                                {trans("rank_goal_website")} 
                                            </VuiTypography>
                                        </VuiBox>
                                        <MyWebsite websites={websites}  setWebsites={setWebsites} />
                                    </VuiBox>
                                </Grid>
                                
                                <Grid item xs={12} md={6} px={5}>
                                    <VuiBox p={5} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white"
                                                sx={{ fontSize: 15 }}
                                            >
                                                {trans("rank_competitorsـwebsite")} 
                                            </VuiTypography>
                                        </VuiBox>
                                        <OtherWebsite websites={websites}  setWebsites={setWebsites} />
                                    </VuiBox>
                                </Grid>
                                
                                <Divider variant="middle" />

                                <Grid item xs={5}>
                                    <VuiBox mb={1}>
                                        <VuiButton onClick={handleInsertWebsitesRanking} color="info" fullWidth size='medium'>
                                            {trans("publicMassage_submit")}
                                        </VuiButton>
                                    </VuiBox>
                                </Grid>

                            </Grid>
                        </VuiBox>

                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    );
}

export default WebsiteManagement;
