import { useParams } from 'react-router-dom';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useContextUIController } from "context";
import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {gql, useApolloClient, useMutation} from '@apollo/client';
import Loader from "examples/loader";
import PaymentPaid from './components/paid';
import PaymentFailed from './components/failed';
import Payment404Failed from './components/404';

const ACTION_PAYMENT_RESULT = gql`
  mutation ($token: String!) {
        action_payment_result(token: $token) {
            token
            success
        }
  }  
`;

const PaymentResult = ({refetchAuthStatus, loggedInUser}) => {

    const [, dispatch] = useContextUIController();
    const [peymentResult,] = useMutation(ACTION_PAYMENT_RESULT);
    const [loader, setLoader] = React.useState(false);
    const [paymentPaid, setPaymentPaid] = React.useState(false);
    const [paymentFailed, setPaymentFailed] = React.useState(false);
    const [payment404Failed, setPayment404Failed] = React.useState(false);
    const [tokenData, setTokenData] = React.useState({});
    const { token } = useParams();


    useEffect(async () => {

        setLoader(true);
        let result = await peymentResult({ variables: {token} });
        setLoader(false);
        
        if(!result?.data?.action_payment_result?.success){
            setPayment404Failed(true);
        }else if(result?.data?.action_payment_result?.success){

            if(result?.data?.action_payment_result?.token?.status === "paid"){
                refetchAuthStatus()
                setPaymentPaid(true);
                setTokenData(result?.data?.action_payment_result?.token)
            }else{
                setPaymentFailed(true)
            }
        }
    },[])



    return (
        <DashboardLayout>

            {loader && <Loader setLoader={loader} />}
            
            <DashboardNavbar />
            <Grid  container px="15px" my="50px" display="flex" justifyContent="center" alignItems="center" >
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card>
                        <VuiBox display="flex" flexDirection="column" height="100%">
                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">
                                {payment404Failed && <Payment404Failed />}
                                {paymentPaid && <PaymentPaid tokenData={tokenData} />}
                                {paymentFailed && <PaymentFailed />}
                            </Grid>
                        </VuiBox>
                    </Card>
                </Grid>
            </Grid>
            
        </DashboardLayout>
    );
}

export default PaymentResult;
