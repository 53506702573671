import React, { useState,useEffect } from 'react';
import { Card, Stack, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import gif from "assets/images/card-background.png";
import { useTranslation } from 'react-i18next';

function KeywordsScraperManagement({loggedInUser}) {
	
	const { t: trans, i18n } = useTranslation();
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	const [ progressColor, setProgressColor ] = useState("info");
	const [ progressSize, setProgressSize ] = useState(1)
	const [ scraperLimit, setScraperLimit ] = useState(0);
	const [ usedScraper, setUsedScraper ] = useState(0);
	const [ sentence, setSentence ] = useState(trans("publicMessage_untilEndOfSubscriptions"));

	useEffect( () => {


		if(loggedInUser?.lastInvoice?.keywordScraperLimitation) setScraperLimit(loggedInUser?.lastInvoice?.keywordScraperLimitation);
		else setScraperLimit(0);

		if(loggedInUser?.lastInvoice?.usedKeywordScraper) setUsedScraper(loggedInUser?.lastInvoice?.usedKeywordScraper);
		else setUsedScraper(0);

		if(loggedInUser?.lastInvoice?.keywordScraperLimitation == loggedInUser?.lastInvoice?.usedKeywordScraper) {
			setProgressColor("error");
			setSentence(trans("publicMassage_activatedSubscriptions"));
		}
		else {
			setProgressColor("info");
			setSentence(trans("publicMessage_untilEndOfSubscriptions"));
		}

		if(!loggedInUser?.lastInvoice?.usedKeywordScraper || !loggedInUser?.lastInvoice?.keywordScraperLimitation) {
			setProgressSize(1);
		}else{
			let size = ((loggedInUser?.lastInvoice?.usedKeywordScraper - loggedInUser?.lastInvoice?.keywordScraperLimitation) / loggedInUser?.lastInvoice?.keywordScraperLimitation) * 100;
			setProgressSize(size + 100);
			// setSentence("تا اتمام اشتراک");
		}
	},[loggedInUser, i18n.language]);

	return (
		<Card
			sx={{
				height: '100%',
				m: 2,
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)),url(${gif})`,
				backgroundSize: "cover",
				backgroundPosition: "50%",
			}}>
			<Grid container sx={{ width: '100%' }}>
				<Grid
					xs={12}
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='60px'>
					<VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
						{trans("dashboard_card_keywordScraperManagement")}
					</VuiTypography>
					
				</Grid>

				<Grid
					display='flex'
					sx={{alignItems: "center", justifyContent: "center", display: "flex"}}
					container
				>
					
					<Grid xs={12} sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
						<CircularProgress
							variant='determinate'
							value={progressSize}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color={progressColor}
						/>
						<VuiBox
							sx={{
								
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								
								<VuiTypography
									color='white'
									variant='d5'
									fontWeight='bold'
									mb='4px'
									sx={({ breakpoints }) => ({
										[breakpoints.only('xl')]: {
											fontSize: '32px'
										}
									})}>
									{usedScraper}
									/
									{scraperLimit}
								</VuiTypography>
							</VuiBox>
						</VuiBox>
					</Grid>
					<VuiTypography color='text' variant='button'>
						{sentence}
					</VuiTypography>	
				</Grid>
			</Grid>
		</Card>
	);
}

export default KeywordsScraperManagement;
