import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Icon from "@mui/material/Icon";
import VuiButton from "components/VuiButton";
import { Link } from "react-router-dom";

function Toolbar({backlinkUrl}) {

    return (
        
            <VuiButton 
                component={Link}
                to={backlinkUrl}
                rel="noreferrer"
                size="large"
                sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                    width: "10px",
                    height: "10px",
                    background: `none !important`,
                    margin: "0",
                    padding: "0",
                    border: "none !important",
                    position: 'absolute',
                    top: 20,
                    color: 'white !important',
                    right: 0,
                    "&:hover": {
                        background: `none !important`,
                        border: "none !important"
                    },
                    "&:after": {
                        background: `none !important`,
                        border: "none !important"
                    },
                })}
                fullWidth
            >
                <Icon 
                    sx={{ 
                        fontWeight: "bold", 
                        fontSize: "25px !important", 
                        color: "white !important",
                        marginBottom: "20px",
                        cursor: "pointer"
                    }}>
                        replyIcon
                </Icon>
            </VuiButton>
        
    );
}

export default Toolbar;
