
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useContextUIController,
  setMiniSidenav,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useContextUIController();
  const { miniSidenav, transparentNavbar } = controller;
  const handleMiniSidenav = () => {setMiniSidenav(dispatch, !miniSidenav);}
  

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox color={light ? "white" : "inherit"}>
              <IconButton
                size="large"
                color="inherit"
                // sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={{color: "white !important" }} className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
