import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {useEffect, useState} from "react";
import Badge from '@mui/material/Badge';
import VuiTypography from "components/VuiTypography";
import gif from "assets/images/header-background.png";
import { useTranslation } from "react-i18next";

function Header({handleSetTabValue, tabValue, setTabValue}) {
    const [tabsOrientation,setTabsOrientation] = useState("horizontal");
	const { t: trans, i18n } = useTranslation();
    
    useEffect(() => {

        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.lg
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();

        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    return (
        <VuiBox position="relative" px="15px" >
            <DashboardNavbar light/>
                <Card sx={{
                    px: 3,
                    mt: 2,
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),url(${gif})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={({breakpoints}) => ({
                        [breakpoints.up("xs")]: {
                            gap: "16px"
                        },
                        [breakpoints.up("xs")]: {
                            gap: "0px"
                        },
                        [breakpoints.up("xl")]: {
                            gap: "0px"
                        }
                    })}>
                        <Grid
                            justifyContent="center"
                            alignItems="center"
                            display="center"
                            item
                            xs={12}
                            md={6}
                            lg={6.5}
                            xl={6}
                            xxl={4}
                            sx={{
                            mx: "auto"
                        }}>
                            <AppBar position="static">
                                <Tabs
                                    orientation={tabsOrientation}
                                    value={tabValue}
                                    onChange={handleSetTabValue}
                                    sx={{
                                        background: "transparent",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}>
                                    <Tab
                                        label={< div > {trans("subscription_header_oneMonth")} < Badge color = "warning" sx = {{ display: "inline-flex", alignItems: "center", color: "white"}}/> </div >}
                                        sx={{
                                            fontSize: "16px",
                                            // marginRight: "10px"
                                        }}/>
                                    <Tab
                                        label={< div > {trans("subscription_header_sixMonth")} < Badge badgeContent = {trans("subscription_header_sixMonthDiscount")} color = "warning" sx = {{ marginLeft: "27px", display: "inline-flex", alignItems: "center", '& .MuiBadge-badge': { fontSize: "16px", }}} /> </div>}
                                        sx={{
                                            fontSize: "17px",
                                            // marginRight: "10px"
                                        }}/>
                                    <Tab
                                        label={< div > {trans("subscription_header_oneYear")} < Badge badgeContent = {trans("subscription_header_oneYearDiscount")} color = "warning" sx = {{ marginLeft: "27px", display: "inline-flex", alignItems: "center", '& .MuiBadge-badge': { fontSize: "16px", }}} /> </div>}
                                        sx={{
                                            fontSize: "17px",
                                            // marginRight: "10px"
                                        }}/>
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Card>
        </VuiBox>
    );
}

export default Header;
