import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { ContextUIControllerProvider } from "context";
import reportWebVitals from "./reportWebVitals"
import ApolloApp from './ApolloApp';

ReactDOM.render(
  <BrowserRouter>
    <ContextUIControllerProvider>
      {/* <App /> */}
      <ApolloApp />
    </ContextUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();