import React, { useState,useRef } from "react";
import { Link } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signUpImage.png";
import { gql } from "@apollo/client/core";
import {useMutation, useQuery} from '@apollo/client';
import Loader from "examples/loader";
import { ErrorAlarm } from "examples/Alert";
import ResendAuthenticationCode from "../components/ResendCode";
import { useTranslation } from "react-i18next";


const ACTION_SEND_CODE = gql`
  mutation ($phoneNumber: String!, $action: String!) {
      action_send_code(phoneNumber: $phoneNumber, action: $action) {
        success
        alert
      }
  }  
`;


const ACTION_SIGN_UP = gql`
mutation ($phoneNumber: String!, $email: String!, $fullname: String!, $verificationCode: String!) {
    action_auth_sign_up(phoneNumber: $phoneNumber, email: $email, fullname: $fullname, verificationCode: $verificationCode) {
      success
      authToken
      alert
    }
}  
`;

function SignUp({onSignIn}) {

  const [confirmationCode, setConfirmationCode] = useState(false);

  const [doSignUp,] = useMutation(ACTION_SIGN_UP);
  const [doSendCode,] = useMutation(ACTION_SEND_CODE);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = React.useState('');
  const [loader, setLoader] = React.useState(false);
	const { t: trans, i18n } = useTranslation();

  const [ formState, setFormState ] = useState({
    phoneNumber: '',
    email: '',
    fullname: ''
  });

  const phoneNumberFieldRef = useRef();
  const emailFieldRef = useRef();
  const fullnameFieldRef = useRef();
  const codeFieldRef = useRef();
  
  const confirmationCodeChange = () => {
    setConfirmationCode(false);
  }

  const confirmationCodeJSX = () => {
    return (
      <>
      <VuiBox
        component="form"
        role="form"
        onSubmit={onSignUpFormSubmit}
        borderRadius="inherit"
        p="5px"
        mt={5}
      >
        <VuiBox>
          <VuiBox mb={1} mr={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
            sx={{ fontSize: 13 }}
            >
              {trans("Authentication_publicMessage_verificationCodeHasBeenSent")}
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="text" id="code" name="code" autoComplete="code" autoFocus inputRef={codeFieldRef}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={6} mb={1}>
          <VuiButton color="info" fullWidth size='large' type="submit">
            {trans("publicMassage_submit")}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiButton variant="text" color="light" size='large' onClick={confirmationCodeChange}>
            {trans("Authentication_publicMessage_editPhoneNumber")}
          </VuiButton>
        </VuiBox>
      </VuiBox>
      <ResendAuthenticationCode phoneNumber={formState?.phoneNumber} action={'sign-up'} setConfirmationCode={setConfirmationCode} setFormState={setConfirmationCode}   />
      </>
    )
  }

  const sendVerificationCode = async (e) => {

    try {

      e.preventDefault();
      setErrorAlert(false);
      setLoader(true);
  
      const phoneNumber = phoneNumberFieldRef.current.value;
      const email = emailFieldRef.current.value;
      const fullname = fullnameFieldRef.current.value;
  
      if(phoneNumber == '' || email == '' || fullname == '') return;
  
      const result = await doSendCode({variables: {phoneNumber, action: 'sign-up' }});
      const success = result?.data?.action_send_code?.success;
  
      setLoader(false);
      
      if(success){
        setFormState(s => ({...s, phoneNumber: phoneNumber}));
        setFormState(s => ({...s, fullname: fullname}));
        setFormState(s => ({...s, email: email}));
        setConfirmationCode(true)
      }else{
        setErrorAlertMsg(result?.data?.action_send_code?.alert?.msg);
        setErrorAlert(false);
        setErrorAlert(true);
      }
    } catch (error) {
        setErrorAlertMsg(error?.message);
        setErrorAlert(false);
        setErrorAlert(true);
    }
  }

  const onSignUpFormSubmit = async (e) => {

    try {

      e.preventDefault();
      setLoader(true);
      setErrorAlert(false);
  
      const phoneNumber = formState?.phoneNumber;
      const email = formState?.email;
      const fullname = formState?.fullname;
      const verificationCode = codeFieldRef.current.value;
  
      if(phoneNumber == '' || email == '' || fullname == '' || verificationCode == '') return;
  
      const result = await doSignUp({variables: {phoneNumber, email, fullname , verificationCode}});
      const success = result?.data?.action_auth_sign_up?.success;

      setLoader(false);

      if(success){
        const authToken = result?.data?.action_auth_sign_up?.authToken;
        if (authToken)
            window.localStorage.setItem('AuthToken', authToken);
  
        setTimeout(onSignIn, 1000);
      }else{
        setErrorAlertMsg(result?.data?.action_auth_sign_up?.alert?.msg);
        setErrorAlert(false);
        setErrorAlert(true);
      }
    } catch (error) {
        setErrorAlertMsg(error?.message);
        setErrorAlert(false);
        setErrorAlert(true);
    }
  }
  
  return (
    <CoverLayout
      // title="Welcome!"
      color="white"
      // description="Use these awesome forms to login or create new account in your project for free."
      image={bgSignIn}
      premotto="Muhammad Reza Marvi"
      motto="MUHAMMAD REZA MARVI"
      cardContent
    >
      {
          loader && <Loader setLoader={loader} />
      }
      {
          errorAlert && <ErrorAlarm openAlarams={errorAlert} alarmProps={{msg: errorAlertMsg}} />
      }
      {
        !confirmationCode && 
          <VuiBox
            component="form"
            role="form"
            onSubmit={sendVerificationCode}
            borderRadius="inherit"
            p="45px"
          >
            <VuiBox mb={3} mt={5}>
              <VuiBox mb={1} mr={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
                sx={{ fontSize: 15 }}
                >
                  {trans("Authentication_publicMessage_phoneNumber")}
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm,
                  })}
                  type="text" id="phoneNumber" name="phoneNumber" autoComplete="phoneNumber" autoFocus inputRef={phoneNumberFieldRef}
                />
              </GradientBorder>
            </VuiBox>
            <VuiBox mb={3} mt={3}>
              <VuiBox mb={1} mr={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
                sx={{ fontSize: 15 }}
                >
                  {trans("Authentication_publicMessage_fullname")}
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm,
                  })}
                  type="text" id="fullname" name="fullname" autoComplete="fullname" inputRef={fullnameFieldRef}
                />
              </GradientBorder>
            </VuiBox>
            <VuiBox mb={3} mt={3}>
              <VuiBox mb={1} mr={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
                  sx={{ fontSize:15}}
                >
                  {trans("Authentication_publicMessage_email")}
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="email" id="email" name="email" autoComplete="email" inputRef={emailFieldRef}
                  sx={({ typography: { size } }) => ({
                    fontSize: size.sm,
                  })}
                />
              </GradientBorder>
            </VuiBox>
            <VuiBox mt={6} mb={1}>
              <VuiButton color="info" fullWidth size='large' type="submit">
                {trans("Authentication_publicMessage_signUp")}  
              </VuiButton>
            </VuiBox>
            <VuiBox mt={3} textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                {trans("Authentication_publicMessage_doYouHaveAlreadyAccount")} {" "}
                <VuiTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  {trans("Authentication_publicMessage_signIn")} 
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </VuiBox>
      }

      { confirmationCode && confirmationCodeJSX() }
      
    </CoverLayout>
  );
}

export default SignUp;
