import React, { useState,useRef } from "react";
import { Link } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signInImage.png";
import { gql } from "@apollo/client/core";
import {useMutation, useQuery} from '@apollo/client';
import { SuccessAlarm ,ErrorAlarm} from "examples/Alert";
import Loader from "examples/loader";
import ResendAuthenticationCode from "../components/ResendCode";
import { useTranslation } from "react-i18next";


const ACTION_SEND_CODE = gql`
  mutation ($phoneNumber: String!, $action: String!) {
      action_send_code(phoneNumber: $phoneNumber, action: $action) {
        success
        alert
      }
  }  
`;

const ACTION_AUTH_SIGN_IN = gql`
mutation ACTION_AUTH_SIGN_IN ($phoneNumber: String!, $verificationCode: String!) {
    action_auth_sign_in(credentials: {phoneNumber: $phoneNumber, verificationCode: $verificationCode}) {
      success
      authToken
      alert
    }
}  
`; 


function SignIn({onSignIn}) {

  const [confirmationCode, setConfirmationCode] = useState(false);
  const phoneNumberFieldRef = useRef();
  const codeFieldRef = useRef();
	const { t: trans, i18n } = useTranslation();

  const [ formState, setFormState ] = useState({
    phoneNumber: ''
  });

  const [errorAlert, setErrorAlert] = React.useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = React.useState('');
  const [loader, setLoader] = React.useState(false);


  const [doSignIn,] = useMutation(ACTION_AUTH_SIGN_IN);
  const [doSendCode,] = useMutation(ACTION_SEND_CODE);

  const confirmationCodeChange = () => {
    setConfirmationCode(false);
  }

  const sendVerificationCode = async (e) => {

    e.preventDefault();
    setErrorAlert(false);
    setLoader(true);
    const phoneNumber = phoneNumberFieldRef.current.value;

    try {
      const result = await doSendCode({variables: {phoneNumber, action: 'sign-in'} });
      const success = result?.data?.action_send_code?.success;

      setLoader(false);

      if(success){
        setFormState(s => ({...s, phoneNumber: phoneNumber}));    
        setConfirmationCode(true)
      }else{
        setErrorAlertMsg(result?.data?.action_send_code?.alert?.msg);
        setErrorAlert(false);
        setErrorAlert(true);
      }
    } catch (e) {
      console.log(e);
      setErrorAlertMsg(e?.message);
      setErrorAlert(false);
      setErrorAlert(true);
    }
  };

  const onSignInFormSubmit = async (e) => {

    try {

      e.preventDefault();
      setErrorAlert(false);
      setLoader(true);

      const phoneNumber = formState?.phoneNumber;
      const verificationCode = codeFieldRef.current.value;

      if(phoneNumber == '' || verificationCode == '') return;

      const result = await doSignIn({variables: {phoneNumber, verificationCode}});
      const success = result?.data?.action_auth_sign_in?.success;

      setLoader(false);

      if(success){
        const authToken = result?.data?.action_auth_sign_in?.authToken;
        if (authToken)
            window.localStorage.setItem('AuthToken', authToken);

        setTimeout(onSignIn, 1000);
      }else{
        setErrorAlertMsg(result?.data?.action_auth_sign_in?.alert?.msg);
        setErrorAlert(false);
        setErrorAlert(true);
      }
    } catch (error) {
      setErrorAlertMsg(error?.message);
      setErrorAlert(false);
      setErrorAlert(true);
    }
  }

  const confirmationCodeJSX = () => {
    return (
      <>
      <VuiBox
        component="form"
        role="form"
        onSubmit={onSignInFormSubmit}
        borderRadius="inherit"
        p="5px"
        mt={5}
      >
        <VuiBox mb={3} mt={20}>
          <VuiBox mb={1} mr={2}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
            sx={{ fontSize: 13 }}
            >
              {trans("Authentication_publicMessage_verificationCodeHasBeenSent")}
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="text" id="code" name="code" autoComplete="code" autoFocus inputRef={codeFieldRef}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={6} mb={1}>
          <VuiButton color="info" fullWidth size='large' type="submit">
            {trans("publicMassage_submit")}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiButton variant="text" color="light" size='large' onClick={confirmationCodeChange}>
              {trans("Authentication_publicMessage_editPhoneNumber")}
          </VuiButton>
        </VuiBox>
      </VuiBox>
      <ResendAuthenticationCode phoneNumber={formState?.phoneNumber} action={'sign-in'} setConfirmationCode={setConfirmationCode} setFormState={setConfirmationCode} />
      </>
    )
  }

  return (
    <CoverLayout
      // title="Nice to see you!"
      color="white"
      // description="Enter your email and password to sign in"
      premotto="Muhammad Reza Marvi"
      motto="MUHAMMAD REZA MARVI"
      image={bgSignIn}
    >
      {
          loader && <Loader setLoader={loader} />
      }
      {
          errorAlert && <ErrorAlarm openAlarams={errorAlert} alarmProps={{msg: errorAlertMsg}} />
      }
      {
        !confirmationCode && 
          <VuiBox sx={{width: "100% !important"}} component="form" role="form" onSubmit={sendVerificationCode}>
            <VuiBox>
              <VuiBox>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium" 
                  sx={{ fontSize: 15 }}
                >
                {trans("Authentication_publicMessage_phoneNumber")}
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput type="text" id="username" name="username" autoComplete="username" autoFocus inputRef={phoneNumberFieldRef} fontWeight="500" />
              </GradientBorder>
            </VuiBox>
            <VuiBox mt={4} mb={1}>
              <VuiButton color="info" fullWidth size='large' type="submit">
                {trans("Authentication_publicMessage_signIn")}
              </VuiButton>
            </VuiBox>
            <VuiBox mt={3} textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                {trans("Authentication_publicMessage_dontHaveAccount")} {" "}
                <VuiTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  {trans("Authentication_publicMessage_signUp")}
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </VuiBox>
      }
      { confirmationCode && confirmationCodeJSX() }
    </CoverLayout>
  );
}

export default SignIn;
