import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CreditBalance from "../PriceStyledCard";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SubscriptionModal from "../Modal";
import Loader from "examples/loader";
import { useTranslation } from "react-i18next";


function SubscriptionColoumn( {subscription} ) {    

    let {
        id,
        prices,
        meta,
        facilities,
        subscription_model,
        time_to_expire,
        languages
    } = subscription;

    const [loader, setLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {setOpen(false)};
    const [formState, setFormState] = React.useState({});
	const { t: trans, i18n } = useTranslation();

    const handleChoosedPackage = (data => {
        setOpen(true)
        setFormState(data);
    });

    return (
        <VuiBox
            sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            overflow: "visible",
            py: "30px"
        }}>
            
            <SubscriptionModal 
                open={open}
                handleClose={handleClose}
                formState={formState}
            />
            
            <VuiBox
                sx={({breakpoints}) => ({
                [breakpoints.only("xl")]: {
                    minHeight: "200px"
                }
            })}>
                <VuiBox 
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                >
                    <VuiTypography
                        variant="lg"
                        color="white"
                        fontWeight="medium"
                    >
                        {subscription_model?.[i18n.language]?.title}
                    </VuiTypography>
                </VuiBox>
                <VuiBox my={3}>
                    <VuiTypography
                        variant="h5"
                        color="white"
                    >
                        {prices?.languages?.[i18n.language]?.price_after_discount} {prices?.languages?.[i18n.language]?.["price-unit"]} / {languages?.[i18n.language]?.title}
                    </VuiTypography> 
                </VuiBox>

                <CreditBalance price={prices} />

                <VuiBox my={4} lineHeight={0}>
                    { facilities?.languages?.[i18n.language] && Object.entries(facilities?.languages?.[i18n.language]).map(([key, value, index]) => (
                        <VuiTypography key={key} variant="button" fontWeight="regular" sx={{color: '#DDE6ED'}} lineHeight="2">
                            {key}: {value} 
                            <br />
                        </VuiTypography>
                    ))}
                </VuiBox>

                <VuiBox my={3} lineHeight={0}>
                    { meta?.data?.[i18n.language]["has-others-data"] && meta?.data?.[i18n.language]["has-others-data"].map((item, index) => (
                        <VuiTypography key={item} variant="button" fontWeight="regular" sx={{color: '#DDE6ED'}} lineHeight="2">
                            <CheckCircleIcon style={{ color: 'green', scale: "1.4", marginLeft:"5px"  }} />  {item}
                            <br />
                        </VuiTypography>
                    ))}
                </VuiBox>

                <VuiBox my={3} lineHeight={0}>
                    { meta?.data?.[i18n.language]["has-not-others-data"] && meta?.data?.[i18n.language]["has-not-others-data"].map((item, index) => (
                        <VuiTypography key={item} variant="button" fontWeight="regular" sx={{color: '#DDE6ED'}} lineHeight="2">
                            <CancelIcon style={{ color: '#E31A1A', scale: "1.4", marginLeft:"5px"  }} />  {item}
                            <br />
                        </VuiTypography>
                    ))}
                </VuiBox>

                <VuiButton color="info" fullWidth size='large'  
                    onClick={ () => {
                        handleChoosedPackage({
                            id,
                            subscription_name: subscription_model?.[i18n.language]?.title,
                            price: prices,
                            time_to_expire,
                        })
                    }}
                >
                    {trans("publicMassage_submit")}
                </VuiButton>
               
            </VuiBox>
        </VuiBox>
    );
}

export default SubscriptionColoumn;
