import React from 'react';
import { useAutocomplete,AutocompleteGetTagProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useRef } from 'react';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
`,
);

const SelectedTagWrapper = styled('div')(
  ({ theme }) => `
  margin-top: 20px;
  width: 100%;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
`,
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid gray;
  background-color: #0f1535;
  border-radius: 13px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    background-color: #0f1535;
    color: #fff;
    font-size: medium;
    font-weight: bold;
    height: 40px;
    border-radius: 13px;
    box-sizing: border-box;
    padding: 6px 10px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  color: whitesmoke;
  font-size: medium;
  height: 24px;
  line-height: 22px;
  width: max-content;
  background-color: transparent;
  border: 1.5px solid gray;
  border-radius: 20px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  margin: 4px;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

export default function CustomizedHook({selectedTags, setSelectedTags}) {

  const {
    getRootProps,
    getInputProps,
    focused,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [],
    multiple: true,
    options: [],
    getOptionLabel: (option) => option.title,
  });

  const inputRef = useRef(null); 

  const handleInputKeyDown = (event) => {
    
    if (event.key === 'Enter' && event.currentTarget.value.trim() !== '') {
      const newValue = [...selectedTags, event.currentTarget.value ];
      setSelectedTags(newValue);
  
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
        getInputProps().reset();
      }
    }
  };

  return (
    <Root>
      <div {...getRootProps()}>

        <InputWrapper className={focused ? 'focused' : ''}>
          
          <input
            {...getInputProps({ ref: inputRef })}
            onKeyDown={handleInputKeyDown}
          />
        </InputWrapper>
      </div>

      <SelectedTagWrapper className={focused ? 'focused' : ''}>

        {selectedTags.map((option, index) => (
          <StyledTag
            key={index}
            label={option}
            onDelete={() => {
              const updatedTags = selectedTags.filter((_, i) => i !== index);
              setSelectedTags(updatedTags);
            }}
          />
        ))}
      </SelectedTagWrapper>
    </Root>
  );
}


