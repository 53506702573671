import Icon from "@mui/material/Icon";
import VuiButton from "components/VuiButton";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { Link } from "react-router-dom";
import borders from "assets/theme/base/borders";
import SVG404 from "examples/Icons/404";
import { useTranslation } from "react-i18next";

const Payment404Failed = ()=> {
    
	const { t: trans, i18n } = useTranslation();

    return(
        <Grid item xs={12} md={8} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="15px"
                    pt="100px"
                    sx={({ palette: { dark } }) => ({
                        backgroundColor: "#070B2B",
                    })}
                >
                <Grid container spacing={4} pt={3} display="flex" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <SVG404 />
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <VuiButton component={Link} to={"/dashboard"} color="info" fullWidth size='medium'>
                            {trans("publicMassage_dashboard")}
                        </VuiButton>
                    </Grid>
                </Grid>
                </VuiBox>
            </GradientBorder>
        </Grid>
    )

};

export default Payment404Failed;
