
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import Toolbar from "examples/Toolbar";
import { gql, useQuery } from "@apollo/client";

import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartDataDashboard } from "./data/lineChartData";
import { lineChartOptionsDashboard } from "./data/lineChartOptions";
import { useEffect, useState } from "react";

const QUERY_KEYWORD_RANKING = gql`
query ($user_id: uuid!) {
    keyword_ranking(where: {_and: [{user_id: {_eq: $user_id}} ]}, order_by: {created_at: asc}) {
        website
        user_id
        rank
        meta
        keyword
        id
        created_at
    }
}
`;

function Statistics({loggedInUser}) { 

    const { data } = useQuery(QUERY_KEYWORD_RANKING, {variables: {user_id: loggedInUser?.id}, notifyOnNetworkStatusChange: true});
    const [ timestamp, setTimestamp ] = useState([]);
    const [ keywordRanking, setKeywordRanking ] = useState([]);

    
    useEffect( () => {

        if(data?.keyword_ranking?.length > 0){

            let organizedData = {};
            let res = {};

            data?.keyword_ranking.forEach(item => {

                const { keyword, website, rank, created_at } = item;
                
                if (!organizedData[keyword]) {
                    organizedData[keyword] = { websites: [], results: [] };
                }
                
                if (!organizedData[keyword].websites.includes(website)) {
                    organizedData[keyword].websites.push(website);
                }

                const timeOfItem = `${new Date(created_at).getFullYear()}/${new Date(created_at).getMonth() + 1}/${new Date(created_at).getDate()}`;
                const dayResults = organizedData[keyword].results.find(result => result[timeOfItem]);

                if (!dayResults) {
                    const dayResult = { [timeOfItem]: {} };
                    dayResult[timeOfItem][website] = rank;
                    organizedData[keyword].results.push(dayResult);
                } else {
                    dayResults[timeOfItem][website] = rank;
                }
            });

            for (const key in organizedData) {
                
                res[key] = {
                    timestamp: [],
                    result: []
                }
            }

            console.log(organizedData);
            
            let timestampVariable = {};
            let keywordRankVariable = {};
            
            // Iterate through the organizedData object
            for (const keyword in organizedData) {
              if (organizedData.hasOwnProperty(keyword)) {
                const keywordData = organizedData[keyword];
                const websites = keywordData.websites;
                const results = keywordData.results;
            
                // Initialize the keyword in the variables
                timestampVariable[keyword] = [];
                keywordRankVariable[keyword] = [];
            
                // Iterate through the results for the keyword
                results.forEach(result => {
                  const timestamp = Object.keys(result)[0];
                  timestampVariable[keyword].push(timestamp);
            
                  // Iterate through the websites for the keyword
                  websites.forEach(website => {
                    const rank = result[timestamp][website];
            
                    // Find or create the entry for the website in keywordRankVariable
                    const websiteEntry = keywordRankVariable[keyword].find(entry => entry.name === website);
                    if (websiteEntry) {
                      // If the entry exists, push the rank
                      websiteEntry.data.push(rank);
                    } else {
                      // If the entry doesn't exist, create a new entry
                      keywordRankVariable[keyword].push({ name: website, data: [rank] });
                    }
                  });
                });
              }
            }
            
            console.log("Timestamp Variable:", timestampVariable);
            console.log("Keyword Rank Variable:", keywordRankVariable);
            setTimestamp(timestampVariable)
            setKeywordRanking(keywordRankVariable)
        }
    },[data]);

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Toolbar px='30px' backlinkUrl={"/rank"} />

            <Grid minHeight={'100vh'} container py='50px' px="15px" display="flex" justifyContent="center" alignItems="center" >
                
                {
                    Object.entries(keywordRanking).length > 0 &&

                    Object.entries(keywordRanking).map(([key, value]) => (
                        <Grid  sx={{padding: "20px"}} item xs={12} md={11} lg={6} xl={6}>  
                            <Card>
                                <VuiBox sx={{ height: "100%" }}>
                                    <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                                        {key}
                                    </VuiTypography>
                                    <VuiBox display="flex" alignItems="center" mb="40px">
                                        <VuiTypography variant="button" color="success" fontWeight="bold">
                                        +5% more{" "}
                                        <VuiTypography variant="button" color="text" fontWeight="regular">
                                            in 2021
                                        </VuiTypography>
                                        </VuiTypography>
                                    </VuiBox>
                                    <VuiBox sx={{ height: "310px" }}>
                                        <LineChart
                                            lineChartData={lineChartDataDashboard(value)}
                                            lineChartOptions={lineChartOptionsDashboard(timestamp[key])}
                                        />
                                    </VuiBox>
                                </VuiBox>
                            </Card>
                        </Grid>
                    ))
                } 

                {
                    Object.entries(keywordRanking).length < 1 &&

                    <VuiTypography variant="button" color="success" fontWeight="bold">
                       
                    </VuiTypography>
                }

            </Grid>
        </DashboardLayout>
    );
}

export default Statistics;
