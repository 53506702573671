import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({setLoader}) => {

    const [open,setOpen] = React.useState(true);

    const handleClose = () => { setOpen(false) };
    const handleBackdropClick = (event) => { event.stopPropagation(); };
    const handleKeyDown = (event) => { if (open) { event.preventDefault(); } };

    React.useEffect( () => {
        setOpen(setLoader);
    },[setLoader]);

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open]);

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: "11001111 !important", background: "rgba(4, 1, 17, 0.84)" }}
                open={open}
                onClick={handleClose}
                onClickCapture={handleBackdropClick}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
      );
}

export default Loader;