import {useEffect} from "react";

// react-router-dom components
import {useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import VuiBox from "components/VuiBox";

import {useContextUIController, setLayout} from "context";

function DashboardLayout({children}) {
    const [controller,
        dispatch] = useContextUIController();
    const {miniSidenav} = controller;
    const {pathname} = useLocation();

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [pathname]);
    return (
        <VuiBox
            sx={({breakpoints, transitions, functions: {
                pxToRem
            }}) => ({
            // p: 1, 
            position: "relative",
            [breakpoints.up("xl")]: {
                marginLeft: miniSidenav
                    ? pxToRem(120)
                    : pxToRem(274),
                transition: transitions.create([
                    "margin-left", "margin-right"
                ], {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.standard
                })
            },
            [breakpoints.down("sm")]: {
              p: "0 !important"
            },
        })}>
            {children}
        </VuiBox>
    );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default DashboardLayout;
