import {forwardRef, useImperativeHandle, useState, useEffect, useMemo, useRef } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import themeRTL from "assets/theme/theme-rtl";
import theme from "assets/theme";
import createCache from "@emotion/cache";
import routes from "routes";
import { useContextUIController, setMiniSidenav, setSideNavEvent } from "context";
import {gql, useQuery, useMutation} from '@apollo/client';
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Dashboard from "layouts/dashboard";
import Suggestion from "layouts/suggestions";
import Subscriptions from "layouts/subscriptions";
import Loading from "examples/Loading";
import Profile from "layouts/profile";
import Category from "layouts/suggestions/components/category";
import Payment from "layouts/payment";
import Loader from "examples/loader";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { setDirection } from "context";
import i18next from 'i18next';
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import SeoMainPage from "layouts/suggestions/main";
import RankMainPage from "layouts/rank";
import WebsiteManagement from "layouts/rank/website";
import KeywordManagement from "layouts/rank/keyword/index";
import Statistics from "layouts/rank/statistics";
import "./assets/theme/base/style.css";

const ACTION_AUTH_SIGN_OUT = gql`
mutation {
  action_auth_sign_out {
    success
  }
}
`;

const ACTION_AUTH_STATUS = gql`
query ACTION_AUTH_STATUS {
    action_auth_status {
        id
        role
        fullname
        email
        phoneNumber
        meta
        paymentStatus
        lastInvoice
        countryRequested
    }
}
`;

i18n
    .use(initReactI18next) 
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ['en', 'fr', 'ir'],
      fallbackLng: "en",
      detection: {
        order: ['cookie', 'path', 'htmlTag', 'subdomain'],
        caches: ['cookie']
      },
      backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json',
      },
      react: { useSuspense: false }
    });

const App = forwardRef((props, ref) => {

  useImperativeHandle(
    ref,
    () => ({
        async recheckAuth() {
            refetchAuthStatus();
        }
    })
  );

  const intendedUrl = useRef('/dashboard');
  const [doSignOut,] = useMutation(ACTION_AUTH_SIGN_OUT);
  const [loggedInUser, setLoggedInUser] = useState({id: null, role: '', fullname: '', phoneNumber: '', email: ''});
  const location = useLocation();
  const history = useHistory();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [loader, setLoader] = useState(false);

  const {data: authData, error: authError, refetch: refetchAuthStatus} = useQuery(ACTION_AUTH_STATUS);
  
  useEffect(() => {

      changeDirectionAndLanguage(authData?.action_auth_status?.countryRequested);
      setLoggedInUser(authData?.action_auth_status);
  }, [authData, setLoggedInUser])

  if (!['/', '/sign-up', '/sign-in'].includes(location.pathname)) {
    intendedUrl.current = location.pathname;
  }

  const handleLogout = async e => {
    setLoader(true);
    const result = await doSignOut();
    setLoader(false);
    intendedUrl.current = '/dashboard';
    if (result.data.action_auth_sign_out.success)
      history.replace('/sign-in');
    refetchAuthStatus();
  }

  const handleSignIn = () => {
      refetchAuthStatus()
  };

  const [controller, dispatch] = useContextUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);


  const changeDirectionAndLanguage = (language) => {

    let languageCode = language?.toLowerCase();

    if(languageCode == 'ir'){

      i18next.changeLanguage(languageCode);
      setDirection(dispatch, "rtl");

    }else if(languageCode == 'fr'){
      i18next.changeLanguage(languageCode);
      setDirection(dispatch, "ltr");

    }else {
      i18next.changeLanguage(languageCode);
      setDirection(dispatch, "ltr");
    }

  }

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (!miniSidenav && onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });


    return direction === "rtl" ? (
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {
              authData && 
              <>
                {layout === "dashboard" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand=""
                      brandName="Muhdrmv"
                      routes={routes}
                      loggedInUser={loggedInUser}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                      handleLogout={handleLogout}
                    />
                  </>
                )}
              </>
          }

          { loader && <Loader setLoader={loader} /> }

          <Switch>

            <Route exact path="/">
              <Loading authError={authError} authLoading={authError} loggedInUser={loggedInUser}
                              intendedUrl={intendedUrl.current}/>
            </Route>

            {
              !authData && <Loading authError={authError} authLoading={authError} loggedInUser={loggedInUser}
                              intendedUrl={intendedUrl.current}/>
            }
            
            <Route path="/dashboard">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Dashboard  loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser}
                              intendedUrl={intendedUrl} refetchAuthStatus={refetchAuthStatus}/>
                }
            </Route>

            <Route exact path="/sign-in">
                {loggedInUser?.id && <Redirect to={intendedUrl.current}/>}
                {loggedInUser?.id === null && <Redirect to={intendedUrl.current}/>}
                {!loggedInUser?.id && <SignIn onSignIn={handleSignIn}/>}
            </Route>

            <Route exact path="/sign-up">
                {loggedInUser?.id && <Redirect to={intendedUrl.current}/>}
                {loggedInUser?.id === null && <Redirect to={intendedUrl.current}/>}
                {!loggedInUser?.id && <SignUp onSignIn={handleSignIn}/>}
            </Route>

            <Route path="/suggestion/seo">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Suggestion loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/suggestion/category">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Category loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/suggestion">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <SeoMainPage loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/website-management">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <WebsiteManagement loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/statistics">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Statistics loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/keyword-management">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <KeywordManagement loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <RankMainPage loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/profile">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Profile loggedInUser={loggedInUser} />
                }
            </Route>
            <Route path="/subscriptions">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Subscriptions />
                }
            </Route>
            <Route path="/payment-result/:token">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Payment loggedInUser={loggedInUser} refetchAuthStatus={refetchAuthStatus} />
                }
            </Route>
            
            
          </Switch>
        </ThemeProvider>
      </CacheProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {
              authData && 
              <>
                {layout === "dashboard" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand=""
                      brandName="Muhdrmv"
                      routes={routes}
                      loggedInUser={loggedInUser}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                      handleLogout={handleLogout}
                    />
                  </>
                )}
              </>
          }

          { loader && <Loader setLoader={loader} /> }

          <Switch>

            <Route exact path="/">
              <Loading authError={authError} authLoading={authError} loggedInUser={loggedInUser}
                              intendedUrl={intendedUrl.current}/>
            </Route>

            {
              !authData && <Loading authError={authError} authLoading={authError} loggedInUser={loggedInUser}
                              intendedUrl={intendedUrl.current}/>
            }
            
            <Route path="/dashboard">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Dashboard  loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser}
                              intendedUrl={intendedUrl} refetchAuthStatus={refetchAuthStatus}/>
                }
            </Route>

            <Route exact path="/sign-in">
                {loggedInUser?.id && <Redirect to={intendedUrl.current}/>}
                {loggedInUser?.id === null && <Redirect to={intendedUrl.current}/>}
                {!loggedInUser?.id && <SignIn onSignIn={handleSignIn}/>}
            </Route>

            <Route exact path="/sign-up">
                {loggedInUser?.id && <Redirect to={intendedUrl.current}/>}
                {loggedInUser?.id === null && <Redirect to={intendedUrl.current}/>}
                {!loggedInUser?.id && <SignUp onSignIn={handleSignIn}/>}
            </Route>

            <Route path="/suggestion/seo">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Suggestion loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/suggestion/category">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Category loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/suggestion">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <SeoMainPage loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/statistics">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Statistics loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/website-management">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <WebsiteManagement loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank/keyword-management">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <KeywordManagement loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/rank">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <RankMainPage loggedInUser={loggedInUser} />
                }
            </Route>

            <Route path="/profile">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Profile loggedInUser={loggedInUser} />
                }
            </Route>
            <Route path="/subscriptions">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Subscriptions />
                }
            </Route>

            <Route path="/payment-result/:token">
                {!loggedInUser?.id && <Redirect to="/sign-in"/>}
                {loggedInUser?.id &&
                  <Payment loggedInUser={loggedInUser} refetchAuthStatus={refetchAuthStatus} />
                }
            </Route>
            
            
          </Switch>
        </ThemeProvider>
      </ThemeProvider>
    )
});

export default App;