
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import VuiBox from "components/VuiBox";
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import VuiTypography from "components/VuiTypography";

import colors from "assets/theme/base/colors";

export default function InvoicesTableSkeleton() {

    const { grey } = colors;
    const { borderWidth } = borders;
  
    return (
    <>
        <TableRow>
            <VuiBox
                component="td"
                p={1}
                textAlign="center"
            >
                <Stack sx={{background: "transparent"}} spacing={1}>
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                    <Skeleton width="100vw" variant="text" sx={{ fontSize: '2rem' }} />
                </Stack>
            </VuiBox>
        </TableRow>

    </>
    );
  }