import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import VuiInput from "components/VuiInput";
import Divider from "@mui/material/Divider";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useContextUIController } from "context";
import React, { useEffect, useState } from "react";
import Platforms from "./components/platform";
import Conditions from "./components/conditions";
import VuiButton from "components/VuiButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {gql, useApolloClient, useMutation} from '@apollo/client';
import Loader from "examples/loader";
import { SuccessAlarm, ErrorAlarm } from "examples/Alert";

import Table from "examples/Tables/Table";
import {keywordScraperColoumns, keywordScraperDataTable, keywordScraperHistoryTable, keywordScraperHistoryColoumns} from "./components/tables/data/suggestionDataTable";
import Excel from "examples/Icons/Execl";
import * as XLSX from 'xlsx';
import { useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';
import Toolbar from "examples/Toolbar";


const ACTION_SUGGESTIONS = gql`
mutation ($keyword: String, $platform: String, $category: String, $category_pos: String, $keyword_space: String, $special_sign: String, $special_sign_pos: String) {
    action_suggestions(keyword: $keyword, platform: $platform, category: $category, category_pos: $category_pos, keyword_space: $keyword_space, special_sign: $special_sign, special_sign_pos: $special_sign_pos) {
        success
        alert
        keywordScraperData
    }
}  
`;

const QUERY_KEYWORD_SCRAPER_HISTORY = gql`
query ($user_id: uuid!) {
    keyword_scraper(where: {user_id: {_eq: $user_id}}) {
      created_at
      id
      invoice_id
      keyword
      meta
      results
    }
  }
`


function Suggestion({loggedInUser}) {

    const [, dispatch] = useContextUIController();
    const [suggestionModule,] = useMutation(ACTION_SUGGESTIONS);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [historyRows, setHistoryRows] = useState([]);
    const [historyColumns, setHistoryColumns] = useState([]);
    const [loader, setLoader] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [successAlertMsg, setSuccessAlertMsg] = React.useState('');
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = React.useState('');
    const [suggestionData, setSuggestionData] = React.useState({});
    const [queryVars, setQueryVars] = useState({user_id: loggedInUser?.id});
    const {data: keywordScraperHistory} = useQuery(QUERY_KEYWORD_SCRAPER_HISTORY, {variables: queryVars, notifyOnNetworkStatusChange: true});
	const { t: trans, i18n } = useTranslation();
    

    useEffect( () => {

        const fetchSuggestionData = async () => {

            if(Object.keys(suggestionData).length > 0){
                let c = await keywordScraperColoumns();
                setColumns(c.columns);
                let s = await keywordScraperDataTable(suggestionData, formState?.keyword_space)
                setRows(s.rows)
            }
            if(keywordScraperHistory?.keyword_scraper){
                let c = await keywordScraperHistoryColoumns();
                setHistoryColumns(c.columns);
                let s = await keywordScraperHistoryTable(keywordScraperHistory?.keyword_scraper, handleCreateHistoryExcel)
                setHistoryRows(s.rows)
            }
        }
        fetchSuggestionData();
    },[suggestionData, keywordScraperHistory])


    const [ formState, setFormState ] = useState({
        keyword: '',
        platform: '',
        category: '',
        special_sign: '',
        keyword_space: 'space',
        category_pos: 'at_end',
        special_sign_pos: 'first-space'
    });

    const handleOnChanged = (e) => {
        setFormState(s => ({...s, [e.target.name]: e.target.value}));
    }

    const handleOnChangedArray = (name, value) => {
        setFormState(s => ({...s, [name]: value}));
    }

    const handleCreateExcel = async () => {

        setErrorAlert(false);
        try {
            let fileName = loggedInUser?.id + new Date().getTime();
            const worksheet = XLSX.utils.json_to_sheet(Object.values(suggestionData).flat());
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${fileName}.xlsx`);
            setSuccessAlertMsg(trans("suggestions_extractedDataIsLoaded"));
            setSuccessAlert(true);
        } catch (error) {
            console.log(error);
            setErrorAlertMsg(trans("suggestions_desiredDataCouldNotBeDownloaded"));
            setErrorAlert(true);
        }
    }

    const handleCreateHistoryExcel = async (id) => {

        setErrorAlert(false);
        try {
            for (let i = 0; i < keywordScraperHistory?.keyword_scraper?.length; i++) {
                if(keywordScraperHistory?.keyword_scraper[i]?.id == id){

                    let x = JSON.parse(keywordScraperHistory?.keyword_scraper[i]?.results);
                    let fileName = loggedInUser?.id + new Date().getTime();
                    
                    for (const key in x) {

                        const worksheet = XLSX.utils.json_to_sheet(x?.[key].flat());
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                        XLSX.writeFile(workbook, `${fileName}.xlsx`);
                    }
                    setSuccessAlertMsg(trans("suggestions_requiredInformationReceived"));
                    setSuccessAlert(true);
                }
            }
            
        } catch (error) {
            console.log(error);
            setErrorAlertMsg(trans("suggestions_desiredDataCouldNotBeDownloaded"));
            setErrorAlert(true);
        }
    }
    
    const handleToStartProject = async () => {

        let result;
        setLoader(true);
        setErrorAlert(false);
        
        try {

            result = await suggestionModule({ variables: {
                keyword: formState?.keyword, 
                platform: formState?.platform, 
                category: formState?.category, 
                category_pos: formState?.category_pos, 
                keyword_space: formState?.keyword_space, 
                special_sign: formState?.special_sign, 
                special_sign_pos: formState?.special_sign_pos
            }});
            
            setLoader(false);

            if(result?.data?.action_suggestions?.success){
                setSuggestionData(result?.data?.action_suggestions?.keywordScraperData);
                if(result?.data?.action_suggestions?.alert?.type == 'error'){
                    setErrorAlert(true);
                    setErrorAlertMsg(result?.data?.action_suggestions?.alert?.msg);
                }else{
                    setSuccessAlert(true);
                    setSuccessAlertMsg(trans("suggestions_requiredInformationReceived"));
                }
            }else{
                setErrorAlert(true);
                setErrorAlertMsg(result?.data?.action_suggestions?.alert?.msg);
            }

            
        } catch (error) {
            setErrorAlert(true);
            setErrorAlertMsg(error?.message);
        }
    }

    return (
        <DashboardLayout>

            { loader && <Loader setLoader={loader} /> }
            
            <DashboardNavbar />
            
            <Grid container px="15px" py="80px" sx={{minHeight: '100vh'}} display="flex" justifyContent="center" alignItems="center" >

                { successAlert && <SuccessAlarm openAlarams={successAlert} alarmProps={{msg: successAlertMsg}} /> }
                { errorAlert && <ErrorAlarm openAlarams={errorAlert} alarmProps={{msg: errorAlertMsg}} /> }
            
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card>
                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">

                                <Toolbar backlinkUrl={'/suggestion'} />

                                <Grid item xs={12} md={7} px={5}>
                                    <VuiBox mb={3} sx={{ width: '100%' }}>
                                        <VuiBox mb={1} ml={0.5}>
                                            <VuiTypography component="label" variant="button" color="white"
                                            sx={{ fontSize: 15 }}
                                            >
                                            {trans("suggestions_keyword")}
                                            </VuiTypography>
                                        </VuiBox>
                                        <GradientBorder
                                            minWidth="100%"
                                            borderRadius={borders.borderRadius.lg}
                                            padding="1px"
                                            backgroundImage={radialGradient(
                                            palette.gradients.borderLight.main,
                                            palette.gradients.borderLight.state,
                                            palette.gradients.borderLight.angle
                                            )}
                                        >
                                            <VuiInput
                                                value={formState.keyword}
                                                name="keyword"
                                                type="text"
                                                sx={({ typography: { size } }) => ({
                                                    fontSize: size.sm,
                                                })}
                                                onChange={handleOnChanged}
                                            />
                                        </GradientBorder>
                                    </VuiBox>
                                </Grid>

                                <Platforms handleOnChangedArray={handleOnChangedArray} />

                                <Divider variant="middle" />

                                <Conditions loggedInUser={loggedInUser} handleOnChanged={handleOnChanged} handleOnChangedArray={handleOnChangedArray} />
                                                
                                <Divider variant="middle" />

                                <Grid item xs={5}>
                                    <VuiBox mb={1}>
                                        <VuiButton onClick={handleToStartProject} color="info" fullWidth size='medium'>
                                            {trans("publicMassage_start")}
                                        </VuiButton>
                                    </VuiBox>
                                </Grid>

                            </Grid>
                        </VuiBox>
                    </Card>
                </Grid>
            </Grid>

            {   
                Object.keys(suggestionData).length > 0 &&
                <VuiBox py={3}>
                    <Card>
                        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <VuiButton color="lite" variant="outlined" size='small' onClick={handleCreateExcel}>
                                <Excel size={"30px"} />
                            </VuiButton>
                        </VuiBox>
                        <VuiBox
                            sx={{
                            "& th": {
                                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                `${borderWidth[1]} solid ${grey[700]}`,
                            },
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                    `${borderWidth[1]} solid ${grey[700]}`,
                                },
                            },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </VuiBox>
                    </Card>
                </VuiBox>           
            }

            
            <VuiBox px="15px" py={3}>
                <Card>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <VuiTypography variant="lg" color="white">
                            {trans("suggestions_HistoryOfPreviousSearch")}
                        </VuiTypography>
                    </VuiBox>
                    <VuiBox
                        sx={{
                        "& th": {
                            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                                `${borderWidth[1]} solid ${grey[700]}`,
                            },
                        },
                        }}
                    >
                        <Table columns={historyColumns} rows={historyRows} />
                    </VuiBox>
                </Card>
            </VuiBox>           

        </DashboardLayout>
    );
}

export default Suggestion;
