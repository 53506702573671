import React, { useEffect, useState } from "react";
import { useContextUIController } from "context";

import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";
import { ProfileColoumns, ProfileDataTable } from "./components/tables/data/DataTable";
import {gql, useMutation, useQuery} from '@apollo/client';
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";
import SubscriptionCards from "./components/Subscription";
import InvoicesTableSkeleton from "./components/Skeleton/InvoicesTable";
import { useTranslation } from "react-i18next";

const QUERY_INVOICES = gql`
query ($user_id: uuid!) {
    invoices(where: {user_id: {_eq: $user_id}}, order_by: {created_at: desc}) {
      amount
      created_at
      id
      meta
      payment_status
      invoice_subscription {
        subscription_models {
          languages
        }
        languages
      }
    }
  }
`;

const QUERY_LAST_PAID_INVOICES = gql`
query ($user_id: uuid!) {
  invoices(where: {payment_status: {_eq: "paid"}, user_id: {_eq: $user_id}}, order_by: {created_at: desc}) {
    created_at
    meta
    invoice_subscription {
      id
      languages
      facilities
      prices
      meta
      subscription_models {
        languages
      }
    }
  }
}
`;

function Profile({ loggedInUser }) {

  const [, dispatch] = useContextUIController();
  const [queryInvoices, setInvoicesQuery] = useState(QUERY_INVOICES);
  const [queryLastPaidInvoices, setLastPaidInvoicesQuery] = useState(QUERY_LAST_PAID_INVOICES);
  const [queryVars, setQueryVars] = useState({user_id: loggedInUser?.id});
  const {data: invoicesData} = useQuery(queryInvoices, {variables: queryVars, notifyOnNetworkStatusChange: true});
  const {data: lastPaidInvoiceData} = useQuery(queryLastPaidInvoices, {variables: queryVars, notifyOnNetworkStatusChange: true});
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(null);
	const { t: trans, i18n } = useTranslation();


  useEffect( () => {
    const fetchData = async () => {
      if(invoicesData){
          let c = await ProfileColoumns();
          setColumns(c.columns);
          let s = await ProfileDataTable(invoicesData?.invoices, trans, i18n.language)
          setRows(s.rows)
      }
    }

    fetchData();
    
  },[invoicesData])

  return (
    <DashboardLayout>
      <Header loggedInUser={loggedInUser} />
      <VuiBox mt={5} mb={3} minHeight = "90vh">
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            justifyContent="center"
            alignItems="center"
            display="flex"
            lg={8.5}
            xs={12}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "2 / 1 / 3 / 3",
              },
            })}
          >

            <Card sx={{width: "100%", margin: "15px"}}>
                <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <VuiTypography variant="lg" color="white">
                        پرداخت ها
                    </VuiTypography>
                </VuiBox>
                <VuiBox
                    sx={{
                    "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                        `${borderWidth[1]} solid ${grey[700]}`,
                    },
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                    },
                    }}
                >
                  {
                    columns && <Table columns={columns} rows={rows} />
                  }
                  {
                    !columns && <InvoicesTableSkeleton /> 
                  }
                </VuiBox>
            </Card>
            
          </Grid>

          <Grid
            item
            justifyContent="center"
            alignItems="center"
            display="flex"
            lg={3.5}
            xs={12}
            sx={{
              width: "100% !important"
            }}
          >
            <SubscriptionCards sx={{width: "100% !important", margin: "15px"}} invoicesData={lastPaidInvoiceData?.invoices?.[0]} />
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Profile;
