
import Grid from "@mui/material/Grid";
import GradientBorder from "examples/GradientBorder";
import borders from "assets/theme/base/borders";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import VuiBox from "components/VuiBox";

export default function SubscriptionColumns() {

    return (
    <>
        <Grid item xs={12} md={6} xl={4}>
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="15px"
                    sx={({ palette: { secondary } }) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <Stack sx={{background: "transparent"}} spacing={1}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={150} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </Stack>
                </VuiBox>
            </GradientBorder>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="15px"
                    sx={({ palette: { secondary } }) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <Stack sx={{background: "transparent"}} spacing={1}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={150} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </Stack>
                </VuiBox>
            </GradientBorder>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="15px"
                    sx={({ palette: { secondary } }) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <Stack sx={{background: "transparent"}} spacing={1}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={150} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </Stack>
                </VuiBox>
            </GradientBorder>
        </Grid>
    </>
    );
  }