import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import VuiButton from "components/VuiButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from 'react-i18next';



function SeoMainPage({loggedInUser}) {

	const { t: trans, i18n } = useTranslation();
 
    return (
        <DashboardLayout>
                        
            <DashboardNavbar />
            
            <Grid minHeight={'100vh'} container px="15px" display="flex" justifyContent="center" alignItems="center" >
               
                <Grid item xs={12} md={11} lg={10} xl={11}>
                    <Card sx={{padding: "80px 0px"}}>
                        <VuiBox display="flex" flexDirection="column" height="100%">

                            <Grid container spacing={3} pt={3} display="flex" justifyContent="center" alignItems="center">

                                <Grid item xs={12} md={6} px={5}>
                                    <VuiButton
                                        component={Link}
                                        to={'/suggestion/seo'}
                                        rel="noreferrer"
                                        size="small"
                                        sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                                            color: `${white.main} !important`,
                                            height: "100%",
                                            m: 2,
                                            py: "32px",
                                            background: `linear-gradient(to right, #000428, #004e92)`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "50%",
                                            "&:hover": {
                                                background: `linear-gradient(to right, #000428, #004e92)`
                                            },
                                        })}
                                        fullWidth
                                    >
                                    
                                        <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h5" fontWeight="regular">
                                            {trans("sidenav_route__seo")}
                                        </VuiTypography>
                                    </VuiButton>
                                </Grid>
                                
                                <Grid item xs={12} md={6} px={5}>
                                    <VuiButton
                                        component={Link}
                                        to={'/suggestion/category'}
                                        rel="noreferrer"
                                        size="small"
                                        sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
                                            color: `${white.main} !important`,
                                            height: "100%",
                                            m: 2,
                                            py: "32px",
                                            background: `linear-gradient(to right, #004e92, #000428)`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "50%",
                                            "&:hover": {
                                                background: `linear-gradient(to right, #004e92, #000428)`
                                            },
                                        })}
                                        fullWidth
                                    >
                                    
                                        <VuiTypography display="flex" justifyContent="center" alignItems="center" color="white" variant="h5" fontWeight="regular">
                                            {trans("sidenav_route__category")}
                                        </VuiTypography>
                                    </VuiButton>
                                </Grid>
                                
                            </Grid>
                        </VuiBox>
                    </Card>
                </Grid>
            </Grid>
     

        </DashboardLayout>
    );
}

export default SeoMainPage;
