import { forwardRef } from "react";
import PropTypes from "prop-types";
import VuiTextArea from 'components/VuiTextArea/TexTAreaInput'
import { useContextUIController } from "context";

const VuiTextAreaInput = forwardRef(({ placeholder, size, icon, error, success, disabled, ...rest }, ref) => {
    let template;
    const [controller] = useContextUIController();

    template = <VuiTextArea  placeholder={placeholder} {...rest} ref={ref} ownerState={{ size, error, success, disabled, placeholder }} />;
  

    return template;
});

// Setting default values for the props of VuiTextAreaInput
VuiTextAreaInput.defaultProps = {
  size: "large",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the VuiTextAreaInput
VuiTextAreaInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default VuiTextAreaInput;
