
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Grid from "@mui/material/Grid";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

import colors from "assets/theme/base/colors";

import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";

function CoverLayout({
  color,
  header,
  title,
  description,
  motto,
  premotto,
  image,
  top,
  cardContent,
  children,
}) {
  const { gradients } = colors;

  return (
    <PageLayout
      background={tripleLinearGradient(
        gradients.cover.main,
        gradients.cover.state,
        gradients.cover.stateSecondary,
        gradients.cover.angle
      )}
    >


      <Grid container alignItems="center" sx={{width: "100% !important", flexBasis:"100% !important", maxWidth: "100% !important"}} display="flex" justifyContent="center" alignItems="center">
        
          <Grid item xs={6} 
              height="100vh"
              sx={({ breakpoints }) => ({
                  overflow: "hidden",
                  [breakpoints.down("lg")]: {
                    display: "none",
                  },
                })}>
              {/* <VuiBox
                height="100%"
                width="50vw"
                display={{ xs: "none", md: "block" }}
                position="absolute"
                top={0}
                left={0}
                sx={({ breakpoints }) => ({
                  overflow: "hidden",
                  [breakpoints.down("xl")]: {
                    mr: "100px",
                  },
                  [breakpoints.down("lg")]: {
                    display: "none",
                  },
                })}
                zIndex={0}
              > */}
                <VuiBox
                  height="100%"
                  sx={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <VuiTypography
                    textAlign={cardContent ? "center" : "start"}
                    variant="subtitle1"
                    fontWeight="medium"
                    color="white"
                    mb="10px"
                    sx={{ mb: 1, letterSpacing: "8px" }}
                  >
                    {premotto}
                  </VuiTypography>
                  <VuiTypography
                    textAlign={cardContent ? "center" : "start"}
                    variant="h2"
                    fontWeight="bold"
                    color="logo"
                    mb="10px"
                    textGradient
                    sx={{ letterSpacing: "8px" }}
                  >
                    {motto}
                  </VuiTypography>
                </VuiBox>
              {/* </VuiBox> */}
          </Grid>

          <Grid height="100vh" sx={{width: "100% !important",maxWidth: "100% !important", padding: "0 !important", margin: "0 !important"}}  item xs={6} display="flex" justifyContent="center" alignItems="center">
              <VuiBox
                sx={({ breakpoints }) => ({
                  width: cardContent ? "400px !important" : "350px !important",
                })}
              >
                {children}
              </VuiBox>
          </Grid>
      </Grid>

      
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
