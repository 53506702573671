import { Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import '../../styles/index.css'
import React,{ useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import zarinpalLogo from "assets/images/shapes/zarinpal.svg";
import stripeLogo from "assets/images/shapes/stripe.svg";
import bitcoinLogo from "assets/images/shapes/bitcoin.svg";

const PaymentGateway = ({setPaymentMethod}) => {

	const { t: trans, i18n } = useTranslation();

    const changeCheckedBorder = (event) => {

        document.querySelectorAll('.checkbox-label img').forEach((image) => {
            image.style.borderRadius = '50%';
            image.style.border = 'none';
        });
          
        const image = event.target;
        image.style.borderRadius = '50%';
        image.style.border = '3px solid #4285f4';
        image.style.padding = '5px';
    };

    const handleChanged = (e) => {
        setPaymentMethod(e?.target.value)
    }
  

    return (
        <>            
            <Grid container spacing={5} pt={1} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <VuiBox mx="20px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("payment_method_chooseYourPaymentMethod")}
                        </VuiTypography>
                    </VuiBox>
                </Grid>
                {
                    process.env.REACT_APP_PAYMENT_ZARINPAL && 
                    <Grid item lg={3} sm={6} xs={6} display="flex" justifyContent="center" alignItems="center">
                        <label className="checkbox-label">
                            <input checked onChange={handleChanged} type="radio" className="checkbox-image platform-image" name="webites" value="zarinpal" />
                            <img width="120" height="120" src={zarinpalLogo} alt="Image 1" onClick={changeCheckedBorder} />
                        </label>
                    </Grid> 
                }
                
                {
                    process.env.REACT_APP_PAYMENT_STRIPE && 
                    <Grid item lg={3} sm={6} xs={6} display="flex" justifyContent="center" alignItems="center">
                        <label className="checkbox-label">
                            <input onChange={handleChanged} type="radio" className="checkbox-image platform-image" name="webites" value="stripe" />
                            <img width="120" height="100" src={stripeLogo} alt="Image 2" onClick={changeCheckedBorder} />
                        </label>
                    </Grid>
                }
                
                {
                    process.env.REACT_APP_PAYMENT_CRYPTO && 
                    <Grid item lg={3} sm={6} xs={6} display="flex" justifyContent="center" alignItems="center">
                        <label className="checkbox-label">
                            <input onChange={handleChanged} type="radio" className="checkbox-image platform-image" name="webites" value="crypto" />
                            <img width="100" height="100" src={bitcoinLogo} alt="Image 3" onClick={changeCheckedBorder} />
                        </label>
                    </Grid>
                }
                
            </Grid>
        </>
    );
}

export default PaymentGateway;