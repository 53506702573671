import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import VuiBox from "components/VuiBox";

import { useContextUIController, setLayout, setDirection } from "context";

function PageLayout({ children }) {
  const [, dispatch] = useContextUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
    // setDirection(dispatch, "rtl");
  }, [pathname]);

  return (
    <VuiBox
      width="100vw"
      maxWidth="100%"
      height="100%"
      minHeight="100vh"
      sx={({ palette: { gradients } }) => ({
        overflowX: "hidden",
        backgroundImage: tripleLinearGradient(
          gradients.cover.main,
          gradients.cover.state,
          gradients.cover.stateSecondary,
          gradients.cover.deg
        ),
        position: "relative",
      })}
    >
      {children}
    </VuiBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
