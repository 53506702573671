import { Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import '../style/index.css'
import React,{ useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Platforms = ({handleOnChangedArray}) => {

    const [ formState, setFormState ] = useState({
        google: false,
        youtube: false,
        digikala: false
    });

    const toggleBorder = (event) => {
        const image = event.target;
        console.log(event);
        image.style.borderRadius = '50%';
        image.style.border = image.style.border ? '' : '3px solid #4285f4';
        image.style.padding = '5px';
    };
	const { t: trans, i18n } = useTranslation();


    const handleChanged = (e) => {
        setFormState(s => ({...s, [e.target.value]: e.target.checked}));
    }

    useEffect( () => {
        const fetchData = async () => {
        
            let arr = [];

            if(formState?.google) arr.push('google')
            if(formState?.youtube) arr.push('youtube')
            if(formState?.digikala) arr.push('digikala');

            let value = arr.toString();
            handleOnChangedArray('platform', value);
        }
        fetchData()
    },[formState]);   

    return (
        <>            
            <Grid container spacing={5} pt={1} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <VuiBox mx="20px">
                        <VuiTypography color="white" variant="lg" sx={{ fontSize: 15}}>
                            {trans("suggestions_platform")}
                        </VuiTypography>
                    </VuiBox>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} display="flex" justifyContent="center" alignItems="center">
                    <label className="checkbox-label">
                        <input onChange={handleChanged} type="checkbox" className="checkbox-image platform-image" name="webites" value="google" />
                        <img width="80" height="80" src="https://img.icons8.com/color/96/google-logo.png" alt="Image 1" onClick={toggleBorder} />
                    </label>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} display="flex" justifyContent="center" alignItems="center">
                    <label className="checkbox-label">
                        <input onChange={handleChanged} type="checkbox" className="checkbox-image platform-image" name="webites" value="youtube" />
                        <img width="80" height="80" src="https://img.icons8.com/3d-fluency/94/youtube-play.png" alt="Image 1" onClick={toggleBorder} />
                    </label>
                </Grid>
            </Grid>
        </>
    );
}

export default Platforms;