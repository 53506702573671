import React, {useEffect, useState} from 'react';
import { Backdrop,CircularProgress ,Container  } from '@mui/material';
import {Redirect} from "react-router-dom";


export default function Loading({authError, loggedInUser, intendedUrl}) {

    const [error, setError] = useState('');
    useEffect( () => {
        setError(authError ? 'Error accessing authentication service' : null);
    }, [authError]);

    return (
        <Container component="main" maxWidth={false} >

            {loggedInUser?.id === '' && <Redirect to={'/sign-in'}/>}
            {loggedInUser?.id && <Redirect to={intendedUrl}/>}
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, background: "rgba(4, 1, 17, 0.84)" }}
                open={true}
            >   
                {error ? error : null}
                { !error && <CircularProgress color="inherit" /> }
                
            </Backdrop>
        </Container>
    );
}