import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import Google from "examples/Icons/Google";
import Youtube from "examples/Icons/Youtube";
import Excel from "examples/Icons/Execl";
import VuiButton from "components/VuiButton";
import { changeTimeZone } from "utilities/Utils";

const keywordScraperColoumns = () => {

  return {
    columns: [
      { name: "platform", align: "left", persianName: 'پلترم' },
      { name: "keyword", align: "left", persianName: 'کلید واژه' },
      { name: "keywordStaus", align: "center", persianName: 'وضعیت کلید واژه' },
      { name: "rank", align: "left", persianName: 'رتبه' },
      { name: "result", align: "center", persianName: 'نتیجه' },
    ],
  }
}

const keywordScraperHistoryColoumns = () => {

  return {
    columns: [
      { name: "keyword", align: "left", persianName: 'کلید واژه' },
      { name: "result", align: "center", persianName: 'نتایج' },
      { name: "created_at", align: "left", persianName: 'تاریخ' }
    ],
  }
}

const keywordScraperDataTable = (suggestionData, space_situation) => {

  let rows = [];
  
  for (let keyword in suggestionData) {

    suggestionData[keyword].forEach(result => {
        let space = space_situation == 'space' ? 'تک فاصله' : 'چسبیده';
        if(result?.text){
          let row = {
            platform: (
              <VuiBox display="flex" alignItems="center" sx={{paddingTop: '10px'}}>

                {result?.search_in == "Google" ? <Google size="25px" /> : null }
                {result?.search_in == "Youtube" ? <Youtube size="25px" /> : null }
                
                <VuiTypography pl="10px" color="white" variant="button" fontWeight="medium" sx={{fontSize: "15px"}}>
                  {result?.search_in}
                </VuiTypography>
              </VuiBox>
            ),
            keyword: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {keyword}
              </VuiTypography>
            ),
            keywordStaus: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {space}
              </VuiTypography>
            ),
            rank: (
              <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
                {result?.ranking}
              </VuiTypography>
            ),
            result: (
              <VuiTypography color="white" fontWeight="medium" sx={{fontSize: "15px"}}>
                {result?.text}
              </VuiTypography>
            ),
          }
          rows.push(row);
        }
    });
  }

  return {rows}
}

const keywordScraperHistoryTable = (keywordScraperData, handleCreateHistoryExcel) => {

  let rows = [];

  keywordScraperData.forEach(result => {

    if(result?.id){
        let row = {
          keyword: (
            <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
              {result?.keyword}
            </VuiTypography>
          ),
          result: (
            <VuiButton color="lite" variant="outlined" size='small' onClick={() => {handleCreateHistoryExcel(result?.id)}}>
                <Excel size={"30px"} />
            </VuiButton>
          ),
          created_at: (
            <VuiTypography color="white" fontWeight="medium"  sx={{fontSize: "15px"}}>
              {changeTimeZone(result?.created_at)}
            </VuiTypography>
          ),
        }
        rows.push(row);
      }
  });

  return {rows}
}

export {
  keywordScraperColoumns,
  keywordScraperHistoryColoumns,
  keywordScraperDataTable,
  keywordScraperHistoryTable
};