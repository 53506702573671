import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { card, cardContent, cardIconBox, cardIcon } from "examples/Sidenav/styles/SidenavCard";
import { useContextUIController } from "context";
import { changeTimeZone } from "utilities/Utils";
import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

function SidenavCard({ color,loggedInUser, handleLogout, ...rest }) {

  const { t: trans, i18n } = useTranslation();

  const [controller] = useContextUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [paymentStatusString, setPaymentStatusString] = useState(trans("publicMassage_finishedSubscriptions")) 


  useEffect( () => {
    if(loggedInUser?.paymentStatus?.invoice_expire){
      if(i18n?.language == 'ir'){
        setPaymentStatusString(`${trans("profile_expiryDate")} ${changeTimeZone(loggedInUser?.paymentStatus?.invoice_expire)}`)
      }else{
        setPaymentStatusString(`${trans("profile_expiryDate")} ${new Date(loggedInUser?.paymentStatus?.invoice_expire).getFullYear() + "/" + (new Date(loggedInUser?.paymentStatus?.invoice_expire).getMonth()+1) + "/" + (new Date(loggedInUser?.paymentStatus?.invoice_expire).getDate()+1)}`)
      }
    }else{
      setPaymentStatusString(trans("publicMassage_finishedSubscriptions"));
    }
  },[loggedInUser, i18n.language])


  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        <VuiBox lineHeight={1}>
          <VuiTypography variant="h6" color="white">
            {loggedInUser?.fullname && loggedInUser?.fullname}
          </VuiTypography>
          <VuiBox mb={1.825} mt={0}>
            <VuiTypography variant="caption" color="white" fontWeight="regular">
              {paymentStatusString}
            </VuiTypography>
          </VuiBox>
          <VuiButton
            onClick={handleLogout}
            rel="noreferrer"
            size="small"
            // sx={{ color: "white !important", background: "red" }}
            sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
              color: `${white.main} !important`,
              background: linearGradient(
                gradients.cardDark.main,
                gradients.cardDark.state,
                gradients.cardDark.deg
              ),
              "&:hover": {
                background: linearGradient(
                  gradients.cardDark.main,
                  gradients.cardDark.state,
                  gradients.cardDark.deg
                ),
              },
            })}
            fullWidth
          >
            {trans("sidenav_card__logout")}
          </VuiButton>
        </VuiBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
